/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Logger, LoggerOptions } from '@validide/logger';
import { CookieInformationComponent, CustomizationManager, CustomizationManagerOptions, ICookieInformationComponent, ICustomizationManager, ICustomizationOptions, IHelpCenterService, IMarketingManager, INotificationsComponent, MarketingManager, NoopHelpCenterService, ToastNotificationsComponent, ZendeskHelpCenterService } from '../../auxiliary-components/index';
import { AnonymousUserManager, ApiVersionChecker, BusMessage, ComposedBackgroundWorker, CookieVersionChecker, DateSkewChecker, II18nManager, IBackgroundWorker, IframeContent, IframeContentEventHandler, IframeLoader, IIframeContent, IIframeContentEvent, IIframeLoader, IListener, ILoggerFactory, IMessageBus, IUserData, IUserManager, LoggerFactory, MessageBus, RemoteI18nManager, UserSessionChangedHandler } from '../../core-components/index';
import { IClearNotifications, IContentResized, IRemoveMicroFrontEnd, ISetHelpCenterSuggestions, IShowMicroFrontEnd, IShowNotification, Types as MT } from '../../messages/index';
import { camelCaseToKebabCase, getEnumMembers, getUrlOrigin, KeyValuePair, sendAsync } from '../../utilities/index';
import { ICoreApplication, IHostApplication, IHostApplicationOptions, ILayoutManager } from '../contracts/index';
import { ConfigurationKeys, ConfigurationValues, Functionalities, HostEndpoints } from '../index';
import { LayoutManager } from './layoutManager';

const iframeContentKeyMetaKey = 'iframeContentKey';

/**
 * @inheritdoc
 */
export class HostApplication implements IHostApplication {
  /**
   * Current window reference.
   */
  protected window: Window;
  /**
   * Logger instance.
   */
  protected logger: Logger | null = null;
  private _disposed = false;
  private _options: IHostApplicationOptions;
  private _configuration: ConfigurationValues | null = null;
  private _loggerFactory: ILoggerFactory | null = null;
  private _userManager: IUserManager | null = null;
  private _customizationManager: ICustomizationManager | null = null;
  private _i18nManager: II18nManager | null = null;
  private _layoutManager: ILayoutManager | null = null;
  private _iframeContent: IIframeContent | null = null;
  private _messageBus: IMessageBus | null = null;
  private _parentMessagesToListenTo: { [key: string]: (boolean | string) } = {
    [MT.C.CMN_MFE_SHOW]: MT.C.CMN_MFE_PROCESS_SHOW // Re-publish the message with a different name to prevent infinite loops.
  };
  private _children: { [key: string]: IIframeLoader } = {};
  private _childMessagesToListenTo: { [key: string]: (boolean | string) } = {
    [MT.C.CMN_MFE_SHOW]: true, // Listen to see if the child requested a new component.
    [MT.C.CMN_HELP_OPEN_WIDGET]: true,
    [MT.C.CMN_HELP_SET_SUGGESTION]: true,
    [MT.C.CMN_NOTIFICATION_SHOW]: true,
    [MT.C.CMN_NOTIFICATION_CLEAR]: true,
    [MT.E.CMN_MFE_RESIZED]: true
  };

  private _messageListeners: KeyValuePair<string, IListener>[] = [];
  private _marketingManger: IMarketingManager | null = null;
  private _helpCenterService: IHelpCenterService | null = null;
  private _dateCheckerWorker: IBackgroundWorker | null = null;
  private _versionCheckerWorker: IBackgroundWorker | null = null;
  private _notificationComponent: INotificationsComponent | null = null;
  private _cookieInformationComponent: ICookieInformationComponent | null = null;
  private _isStandAlone: boolean | null = null;
  private _userSessionChangedHandler: UserSessionChangedHandler | null;
  private _userLogoutListener: IListener | null;
  private _customizationRefreshListener: IListener | null;
  private _auxiliaryInitializationRef = 0;
  /**
   * Create a new instance.
   *
   * @param {Window} window A reference to the current window.
   * @param {IHostApplicationOptions} options The options to build the current instance.
   */
  constructor(window: Window, options: IHostApplicationOptions) {
    this.window = window;
    this._options = options;

    this._userSessionChangedHandler = async () => {
      const user = await this.userManager.getUser();
      this.messageBus.publish(
        new BusMessage<IUserData | null>(MT.E.CMN_USER_CHANGED, user)
      );
    };

    this._userLogoutListener = async () => {
      await this.userManager.signOut();
    };

    this._customizationRefreshListener = async (_messageName, message: BusMessage<IUserData | null>) => {
      this.customizationManager.refreshConfiguration(message.data);
      const customizations = await this.customizationManager.getConfiguration();

      this.messageBus.publish(
        new BusMessage<ICustomizationOptions>(MT.E.CMN_CUSTOMIZATION_CHANGED, customizations)
      );
    };
  }

  private _republishMessage(message: BusMessage<any>, republishType: boolean | string): void {
    if (!republishType)
      return;

    const msg = new BusMessage<any>(
      typeof republishType === 'string' ? republishType : message.name,
      message.data,
      message.correlationId,
      message.uuid,
      message.timestamp);
    msg.metadata = message.metadata;

    this.messageBus.publish<any>(msg);
  }

  private _parentMessageListener(data: any): void {
    const message = typeof data === 'object'
      ? data as BusMessage<any>
      : JSON.parse(data || 'null');
    if (typeof message === 'object' && message !== null) {
      this._republishMessage(message, this._parentMessagesToListenTo[message.name]);
    }
  }

  private _childMessageListener(e: IIframeContentEvent, contentKey: string): void {
    const message = e.data as BusMessage<any>;
    if (typeof message === 'object' && message !== null) {
      message.metadata[iframeContentKeyMetaKey] = contentKey;
      this._republishMessage(message, this._childMessagesToListenTo[message.name]);
    }
  }

  private _showMicroFrontEndListener(name: string, message: BusMessage<IShowMicroFrontEnd>): void {
    if (this.isStandAlone) {
      void this._showMicroFrontEndListenerLocally(name, message);
    } else {
      this.messageParent(message);
    }
  }

  private async _showMicroFrontEndListenerLocally(_name: string, message: BusMessage<IShowMicroFrontEnd>): Promise<void> {
    const mfeId = message.data.url;
    let child = this._children[mfeId];
    if (!child) {
      child = await this.createIframeLoader(
        e => this._childMessageListener(e, mfeId),
        () => this.messageBus.publish(new BusMessage<IRemoveMicroFrontEnd>(
          MT.C.CMN_MFE_REMOVE,
          {
            url: message.data.url
          }
        )),
        message.data
      );
      this._children[mfeId] = child;
    }

    await child.waitMount();
    child.messageChild(message);
  }

  private _removeMicroFrontEndListener(name: string, message: BusMessage<IRemoveMicroFrontEnd>): void {
    if (this.isStandAlone) {
      this._removeMicroFrontEndListenerLocally(name, message);
    } else {
      this.messageParent(message);
    }
  }

  private _removeMicroFrontEndListenerLocally(_name: string, message: BusMessage<IRemoveMicroFrontEnd>): void {
    const mfeId = message.data.url;
    const child = this._children[mfeId];

    if (child) {
      delete this._children[mfeId];
      void child.dispose();
    }
  }

  private _resizeMicroFrontEndListener(_name: string, message: BusMessage<IContentResized>): void {
    const iframeId = this._children[message.metadata[iframeContentKeyMetaKey]]?.iframeId || '';
    const iframe: HTMLIFrameElement | null = iframeId ? this.window.document.getElementById(iframeId) as HTMLIFrameElement : null;
    if (iframe) {
      this.resizeMicroFrontEndListener(iframe, message.data);
    }
  }

  private async _getConfigurationValues(): Promise<ConfigurationValues> {
    const configuration = HostApplication.getBodyConfiguration(this.window);
    const url = this._options.apiUrlTransformer!(`${configuration[ConfigurationKeys.hostRootPathAbsolute]!.replace(/\/$/g, '')}${HostEndpoints.configuration}`);
    const remoteResponse = await sendAsync({ url: url, method: 'GET' });

    if (remoteResponse.request.status !== 200)
      throw new Error('Failed to load remote configuration.');

    return {
      ...configuration,
      ...JSON.parse(remoteResponse.request.responseText)
    } as ConfigurationValues;
  }

  private async _configureLoggerFactory(): Promise<ILoggerFactory> {
    const factory = await this.resolveLoggerFactory();
    this.logger = factory.getLogger(`${this.configuration[ConfigurationKeys.appName]!}-host`);

    this.logger.debug('Logger initialized');

    return factory;
  }

  private async _disposeLoggerFactory() {
    if (this._loggerFactory) {
      await this._loggerFactory.dispose();
      this._loggerFactory = null;
    }
  }

  private async _initializeI18nManager(): Promise<II18nManager> {
    this._addMessageListener(MT.E.CMN_CUSTOMIZATION_CHANGED, (_name, messageData: BusMessage<ICustomizationOptions>) => {
      this.i18nManager.setCulture(messageData.data.culture);
    });

    this._addMessageListener(MT.E.CMN_I18N_RESOURCES_UPDATED, (_name, messageData: BusMessage<string>) => {
      this.window.document.documentElement.setAttribute('lang', messageData.data);
    });

    const i18nManager = await this.resolveI18nManger();
    const user = await this.userManager.getUser();
    this.customizationManager.refreshConfiguration(user);
    const customizations = await this.customizationManager.getConfiguration();

    i18nManager.setCulture(customizations.culture);
    await i18nManager.loadResources();

    return i18nManager;
  }

  private async _initializeLayoutManager(): Promise<ILayoutManager> {
    const layoutManager = await this.resolveLayoutManager();
    await layoutManager.initialize();
    return layoutManager;
  }

  private async _configureUserManager(): Promise<IUserManager> {
    const userManager = await this.resolveUserManager();

    await userManager.initialize();
    userManager.addUserSessionChangedHandler(this._userSessionChangedHandler!);
    this.messageBus.subscribe(MT.C.CMN_USER_SIGNOUT, this._userLogoutListener!);
    return userManager;
  }

  private async _initializeCustomizationManager(): Promise<ICustomizationManager> {
    const customizationManager = await this.resolveCustomizationManager();
    await customizationManager.initialize();
    this.messageBus.subscribe(MT.E.CMN_USER_CHANGED, this._customizationRefreshListener!);
    return customizationManager;
  }

  private async _disposeI18nManager() {
    if (this._i18nManager) {
      await this._i18nManager.dispose();
      this._i18nManager = null;
    }
  }

  private async _disposeLayoutManager() {
    if (this._layoutManager) {
      await this._layoutManager.dispose();
      this._layoutManager = null;
    }
  }

  private async _initializeMarketingManager(user: IUserData | null): Promise<IMarketingManager> {
    const marketingManger = await this.resolveMarketingManager();

    if (this.isStandAlone && user && user.profile.functionalities.indexOf(Functionalities.MarketingNotification) !== -1) {
      marketingManger.initialize();
    }
    return marketingManger;
  }

  private async _disposeMarketingManager(): Promise<void> {
    if (this._marketingManger === null)
      return;
    this._marketingManger = null;
  }

  private async _initializeHelpCenter(): Promise<IHelpCenterService> {
    let showHelpCenter =  this.isStandAlone;
    if (showHelpCenter) {
      const user = await this.userManager.getUser();
      const expiredUser = (user?.expired ?? true);
      if (expiredUser) {
        showHelpCenter = false; // Only show help center to authenticated users.
      }
    }

    const helpCenter: IHelpCenterService = showHelpCenter
      ? await this.resolveHelpCenter()
      : new NoopHelpCenterService();

    const isStandAlone = this.isStandAlone;

    if (isStandAlone) {
      // Only initialize and show if the component in stand-alone.



      this._addMessageListener(MT.E.CMN_CUSTOMIZATION_CHANGED, (_name, messageData: BusMessage<ICustomizationOptions>) => {
        helpCenter.setLocale(messageData.data.culture);
      });
    }

    // These messaged need to be forwarded to parent if we are not stand alone.
    const listeners: KeyValuePair<string, IListener>[] = [
      {
        key: MT.C.CMN_HELP_OPEN_WIDGET,
        value: () => { this._helpCenterService!.openWidget(); }
      },
      {
        key: MT.C.CMN_HELP_SET_SUGGESTION,
        value: (_name, messageData: BusMessage<ISetHelpCenterSuggestions>) => {
          this._helpCenterService!.setHelpCenterSuggestions(messageData.data);
        }
      }
    ];

    for (const item of listeners) {
      if (isStandAlone) {
        this._addMessageListener(item.key, item.value);
      } else {
        this._addMessageListener(item.key, (_name, message) => this.messageParent(message));
      }
    }


    return helpCenter;
  }

  private async _disposeHelpCenter(): Promise<void> {
    if (this._helpCenterService) {
      this._helpCenterService.closeWidget();
      this._helpCenterService.hideWidget();
    }
    this._helpCenterService = null;
  }

  private async _initializeDateChecker(): Promise<IBackgroundWorker> {
    const worker = await this.resolveDateChecker();
    if (this.isStandAlone) {
      await worker.initialize();
      await worker.start();
    }
    return worker;
  }

  private async _disposeDateChecker(): Promise<void> {
    if (this._dateCheckerWorker) {
      await this._dateCheckerWorker.dispose();
    }
    this._dateCheckerWorker = null;
  }

  private async _initializeVersionChecker(): Promise<IBackgroundWorker> {
    const worker = await this.resolveVersionChecker();
    await worker.initialize();
    await worker.start();
    return worker;
  }

  private async _disposeVersionChecker(): Promise<void> {
    if (this._versionCheckerWorker) {
      await this._versionCheckerWorker.dispose();
    }
    this._versionCheckerWorker = null;
  }

  private async _initializeCookieInformation(): Promise<ICookieInformationComponent> {
    const checker = await this.resolveCookieInformation();
    if (this.isStandAlone) {
      checker.check();

    }
    return checker;
  }

  private async _disposeCookieInformation(): Promise<void> {
    if (this._cookieInformationComponent) {
      await this._cookieInformationComponent.dispose();
    }
    this._cookieInformationComponent = null;
  }

  private async _initializeNotifications(): Promise<INotificationsComponent> {
    const notificationsComponent = await this.resolveNotificationsComponent();

    await notificationsComponent.initialize();

    // These messaged need to be forwarded to parent if we are not stand alone.
    const listeners: KeyValuePair<string, IListener>[] = [
      {
        key: MT.C.CMN_NOTIFICATION_SHOW,
        value: (_name, messageData: BusMessage<IShowNotification>) => {
          this._notificationComponent!.notify(messageData.data);
        }
      },
      {
        key: MT.C.CMN_NOTIFICATION_CLEAR,
        value: (_name, messageData: BusMessage<IClearNotifications>) => {
          this._notificationComponent!.clearNotifications(messageData.data.group);
        }
      }
    ];

    const isStandAlone = this.isStandAlone;
    for (const item of listeners) {
      if (isStandAlone) {
        this._addMessageListener(item.key, item.value);
      } else {
        this._addMessageListener(item.key, (_name, message) => this.messageParent(message));
      }
    }

    return notificationsComponent;
  }

  private async _disposeNotifications(): Promise<void> {
    if (this._notificationComponent) {
      await this._notificationComponent.dispose();
    }
    this._notificationComponent = null;
  }

  private async _initializeIframeContent(): Promise<void> {
    if (!this.isStandAlone) {
      this._iframeContent = await this.createIframeContent(data => this._parentMessageListener(data));
      this.signalBusyState(true);
    }

    this._addMessageListener(
      MT.C.CMN_MFE_SHOW,
      (name, message) => this._showMicroFrontEndListener(name, message)
    );


    this._addMessageListener(
      MT.C.CMN_MFE_REMOVE,
      (name, message) => this._removeMicroFrontEndListener(name, message)
    );


    this._addMessageListener(
      MT.E.CMN_MFE_RESIZED,
      (name, message) => this._resizeMicroFrontEndListener(name, message)
    );
  }

  private async _disposeIframeContent() {
    if (this._iframeContent) {
      await this._iframeContent.dispose();
    }
    this._iframeContent = null;
  }

  private _addMessageListener(messageName: string, listener: IListener): void {
    this._messageListeners.push({ key: messageName, value: listener });
    this.messageBus.subscribe(messageName, listener);
  }

  private _removeMessageListeners() {
    let kvp = this._messageListeners.pop();
    while (kvp) {
      this.messageBus.unsubscribe(kvp.key, kvp.value);
      kvp = this._messageListeners.pop();
    }
  }

  private async _disposeCoreApplication() {
    if (this.coreApplication) {
      await this.coreApplication.dispose();
      this.coreApplication = null;
    }
  }

  private async _disposeCustomizationManager(): Promise<void> {
    if (this._customizationManager) {
      this.messageBus.unsubscribe(MT.E.CMN_USER_CHANGED, this._customizationRefreshListener!);
      this._customizationRefreshListener = null;
      await this._customizationManager.dispose();
      this._customizationManager = null;
    }
  }

  private async _disposeUserManager() {
    if (this._userManager) {
      this._userManager.removeUserSessionChangedHandler(this._userSessionChangedHandler!);
      this.messageBus.unsubscribe(MT.C.CMN_USER_SIGNOUT, this._userLogoutListener!);
      this._userLogoutListener = null;
      this._userSessionChangedHandler = null;
      await this._userManager.dispose();
      this._userManager = null;
    }
  }

  private async _disposeMessageBus() {
    if (this._messageBus) {
      await this._messageBus.dispose();
      this._messageBus = null;
    }
  }

  private async _initializeAuxiliaryComponents(): Promise<void> {
    const user = await this.userManager.getUser();

    await Promise.all([
      this._initializeNotifications().then(r => this._notificationComponent = r),
      this._initializeMarketingManager(user).then(r => this._marketingManger = r),
      this._initializeHelpCenter().then(r => this._helpCenterService = r),
      this._initializeDateChecker().then(r => this._dateCheckerWorker = r),
      this._initializeVersionChecker().then(r => this._versionCheckerWorker = r),
      this._initializeCookieInformation().then(r => this._cookieInformationComponent = r)
    ]);
  }

  private async _refreshUserSettings(): Promise<void> {
    // Keep the user call ahead of the customization in order to get the user details.
    const user = await this.userManager.getUser();
    this.customizationManager.refreshConfiguration(user);
  }

  /**
   * Get the URL for a certain HOST endpoint.
   *
   * @param {HostEndpoints} endpoint The needed endpoint.
   */
  protected getHostUrl(endpoint: HostEndpoints): string {
    return this._options.apiUrlTransformer!(`${this.configuration[ConfigurationKeys.hostRootPathAbsolute]!.replace(/\/$/g, '')}${endpoint}`);
  }

  /**
   * Create the IIframeLoader controller.
   *
   * @param {IShowMicroFrontEnd} command The command information.
   * @param {String} mfeId The ID of the element to create.
   */
  protected async createIframeLoader(dataHandler: IframeContentEventHandler,
    destroyedHandler: IframeContentEventHandler,
    command: IShowMicroFrontEnd): Promise<IIframeLoader> {
    const loader = new IframeLoader(this.window, {
      url: command.url,
      events: {
        data: dataHandler,
        destroyed: destroyedHandler
      },
      parent: command.parent || 'body',
      iframeAttributes: {
        'allowtransparency': 'true',
        'frameborder': '0'
      },
      wrapperAttributes: {
        ...command.attributes
      },
      isModal: command.isModal
    });

    return loader;
  }

  /**
   * Create the IIframeContent controller.
   */
  protected async createIframeContent(messageHandler: (data: any) => void): Promise<IIframeContent> {
    const parentOrigin = await this._options.getParentOrigin!();

    return new IframeContent(this.window,
      {
        parentOrigin: parentOrigin,
        messageHandler: messageHandler
      }
    );
  }

  /**
   * Resolve the message bus instance.
   */
  protected async resolveMessageBus(): Promise<IMessageBus> {
    return new MessageBus();
  }

  /**
   * Resolve the logger factory instance.
   */
  protected async resolveLoggerFactory(): Promise<ILoggerFactory> {
    const url = this.getHostUrl(HostEndpoints.log);
    const logLevel = LoggerOptions.getLevel(this.configuration[ConfigurationKeys.logLevel]!);
    return new LoggerFactory(this.window, url, 'POST', logLevel);
  }

  /**
   * Resolve the help center service instance.
   */
  protected async resolveHelpCenter(): Promise<IHelpCenterService> {
    const customizationOptions = await this.customizationManager.getConfiguration();

    const helpCenter = new ZendeskHelpCenterService(
      this.window,
      {
        scriptUrl: this.configuration[ConfigurationKeys.zendeskUrl]!,
        locale: customizationOptions.culture,
        themeColor: customizationOptions.themeColors.primary
      }
    );

    await helpCenter.initialize();

    return helpCenter;
  }

  /**
   * Resolve the internationalization manager instance.
   */
  protected async resolveI18nManger(): Promise<II18nManager> {
    const url = this._options.apiUrlTransformer!(`${this.configuration[ConfigurationKeys.appRootPathAbsolute]!.replace(/\/$/g, '')}/i18n/PLACEHOLDER_LANG.json?__=PLACEHOLDER_VERSION`);
    const version = this.configuration[ConfigurationKeys.appVersion]!;
    return new RemoteI18nManager(
      url,
      version,
      this.loggerFactory.getLogger('RemoteI18nManager'),
      undefined,
      this.window.navigator.language,
      MT.E.CMN_I18N_RESOURCES_UPDATED,
      this.messageBus
    );
  }

  /**
   * Resolve the layout manager instance.
   */
  protected async resolveLayoutManager(): Promise<ILayoutManager> {
    return new LayoutManager(this.window, this);
  }

  /**
   * Resolve the marketing manager instance.
   */
  protected async resolveMarketingManager(): Promise<IMarketingManager> {
    return new MarketingManager(this.window, this.configuration[ConfigurationKeys.marketingUrl]!);
  }

  /**
   * Resolve the user manager instance.
   */
  protected async resolveUserManager(): Promise<IUserManager> {
    return new AnonymousUserManager();
  }

  /**
   * Resolve the customization manager instance.
   */
  protected async resolveCustomizationManager(): Promise<ICustomizationManager> {
    const options = new CustomizationManagerOptions();
    options.realmUrl = this.configuration[ConfigurationKeys.realmUrl]!;
    options.themesUrl = this.configuration[ConfigurationKeys.themeUrl]!;
    options.timezoneUrl = this.getHostUrl(HostEndpoints.timezone);
    return new CustomizationManager(
      this.window,
      this.loggerFactory.getLogger('CustomizationManager'),
      options
    );
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected async resolveNotificationsComponent(): Promise<INotificationsComponent> {
    return new ToastNotificationsComponent(
      this.window,
      this.i18nManager
    );
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected async resolveDateChecker(): Promise<IBackgroundWorker> {
    return new DateSkewChecker(
      this.window,
      this.getHostUrl(HostEndpoints.dateTime),
      this.i18nManager,
      this.configuration[ConfigurationKeys.systemRequirementsUrl]!,
      this.messageBus,
      this.loggerFactory.getLogger('DateSkewChecker')
    );
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected async resolveVersionChecker(): Promise<IBackgroundWorker> {
    return new ComposedBackgroundWorker([
      new ApiVersionChecker(
        this.window,
        this.getHostUrl(HostEndpoints.versions),
        this.messageBus,
        this.i18nManager,
        this.loggerFactory.getLogger('VersionChecker')
      ),
      new CookieVersionChecker(
        this.window,
        this.messageBus,
        this.i18nManager,
        `cmn_web_${this.configuration[ConfigurationKeys.appName]!}_r`,
        '1'
      )
    ]);
  }

  /**
   * Resolve the NotificationsComponent instance.
   */
  protected async resolveCookieInformation(): Promise<ICookieInformationComponent> {
    return new CookieInformationComponent(
      this.window,
      this.i18nManager,
      this.configuration[ConfigurationKeys.cookiesInformationUrl]!
    );
  }

  /**
   * Set the missing options default values.
   */
  protected setConfigurationDefaults(): void {
    if (typeof (this._options.getParentOrigin) !== 'function') {
      this._options.getParentOrigin = () => Promise.resolve(
        getUrlOrigin(this.window.document, this.window.location.href)
      );
    }

    if (typeof (this._options.apiUrlTransformer) !== 'function') {
      this._options.apiUrlTransformer = url => url;
    }

    if (typeof (this._options.auxiliaryDelay) !== 'number') {
      this._options.auxiliaryDelay = 5_000;
    }
  }

  /**
   * Listener method to resize the micro-frontend.
   *
   * @param {HTMLIFrameElement} iframe The IFRAME containing the micro-frontend.
   * @param {IContentResized} data The resize data.
   */
  protected resizeMicroFrontEndListener(iframe: HTMLIFrameElement, data: IContentResized): void {
    if (!iframe.parentElement || iframe.parentElement === this.window.document.body)
      return; // We do not resize the parent if it's the body.

    if (typeof data.height !== 'undefined') {
      iframe.parentElement.style.height = data.height === null ? '' : `${data.height.toFixed(0)}px`;
    }

    if (typeof data.width !== 'undefined') {
      iframe.parentElement.style.width = data.width === null ? '' : `${data.width.toFixed(0)}px`;
    }
  }

  /**
   * The core application being hosted.
   */
  protected coreApplication: null | ICoreApplication = null;
  /**
   * This is the method called internally by the public `isStandAlone` property.
   * When `true` this instance is the main application in the page.
   * When `false` this instance is the being mounted as part of a bigger application.
   */
  protected isStandAloneCore(): boolean {
    return this.window === this.window.parent;
  }

  /**
   * @inheritdoc
   */
  public get loggerFactory(): ILoggerFactory { return this._loggerFactory!; }

  /**
   * @inheritdoc
   */
  public get i18nManager(): II18nManager { return this._i18nManager!; }

  /**
   * @inheritdoc
   */
  public get userManager(): IUserManager { return this._userManager!; }

  /**
   * @inheritdoc
   */
  public get customizationManager(): ICustomizationManager { return this._customizationManager!; }

  /**
   * @inheritdoc
   */
  public get layoutManager(): ILayoutManager { return this._layoutManager!; }

  /**
   * @inheritdoc
   */
  public get configuration(): ConfigurationValues { return this._configuration || {}; }

  /**
   * @inheritdoc
   */
  public hasChild(id?: string): boolean {
    if (typeof id === 'undefined')
      return Object.keys(this._children).length > 0;

    return typeof this._children[id] === 'object';
  }

  /**
   * @inheritdoc
   */
  public buildUrl(url: string): string {
    const replaced = url.replace(/\{\w*\}/gi, match => {
      const configKey = match.substring(1, match.length - 1);
      const configValue = this.configuration[configKey];
      if (configValue) {
        return configValue;
      } else {
        return match;
      }
    });

    return this._options.apiUrlTransformer!(replaced);
  }

  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public messageParent(data: any): void {
    this._iframeContent?.messageParent(data);
  }

  /**
   * @inheritdoc
   */
  public signalBusyState(isBusy: boolean): void {
    this._iframeContent?.signalBusyState(isBusy);
  }

  /**
   * @inheritdoc
   */
  public get messageBus(): IMessageBus {
    return this._messageBus!;
  }

  /**
   * @inheritdoc
   */
  public get isStandAlone(): boolean {
    if (typeof this._isStandAlone === 'boolean')
      return this._isStandAlone;

    try {
      this._isStandAlone = this.isStandAloneCore();
    } catch {
      this._isStandAlone = false;
    }

    return this._isStandAlone;
  }

  /**
   * @inheritdoc
   */
  public get coreApplicationMetadata(): { [key: string]: any } {
    return this.coreApplication?.metadata ?? {};
  }

  /**
   * @inheritdoc
   */
  public async initialize(): Promise<IHostApplication> {
    this.setConfigurationDefaults();
    this._messageBus = await this.resolveMessageBus();

    await this._initializeIframeContent();

    this._configuration = await this._getConfigurationValues();
    this._loggerFactory = await this._configureLoggerFactory();
    this._userManager = await this._configureUserManager();
    this._customizationManager = await this._initializeCustomizationManager();
    this.coreApplication = this._options.createCoreApp(this.window, this);
    this._i18nManager = await this._initializeI18nManager();
    this._layoutManager = await this._initializeLayoutManager();

    await this.beforeCoreApplicationInitialize();
    await this._refreshUserSettings();
    await this.coreApplication.initialize();
    this._auxiliaryInitializationRef = this.window.setTimeout(() => this._initializeAuxiliaryComponents(), this._options.auxiliaryDelay);
    await this.coreApplication.mount(this._layoutManager.getApplicationElement());
    await this.afterCoreApplicationInitialize();

    this.signalBusyState(false);

    return this;
  }

  /**
   * Method to dispose of any instance resources.
   */
  protected disposeCore(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Called before the core application in initialized
   */
  protected beforeCoreApplicationInitialize(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Called after the core application in initialized
   */
  protected afterCoreApplicationInitialize(): Promise<void> {
    return Promise.resolve();
  }


  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    if (this._disposed)
      return;

    this._disposed = true;
    this.window.clearTimeout(this._auxiliaryInitializationRef);
    this._auxiliaryInitializationRef = 0;

    await this.disposeCore();
    await this._disposeIframeContent();
    await this._disposeCoreApplication();
    this._removeMessageListeners();



    await Promise.all([
      this._disposeNotifications(),
      this._disposeMarketingManager(),
      this._disposeHelpCenter(),
      this._disposeVersionChecker(),
      this._disposeDateChecker(),
      this._disposeCookieInformation()
    ]);
    await this._disposeLayoutManager();
    await this._disposeI18nManager();
    await this._disposeCustomizationManager();
    await this._disposeUserManager();
    await this._disposeMessageBus();
    await this._disposeLoggerFactory();
  }

  /**
   * Set configuration values on the BODY element.
   * This should only be used for development as in production the server should render these.
   *
   * @param {Window} window Reference to the current window.
   * @param {ConfigurationValues} configuration Configuration values to add.
   */
  public static setBodyConfiguration(window: Window, configuration: ConfigurationValues): void {
    const configurationKeys = Object.keys(configuration);
    for (const confKey of configurationKeys) {
      const htmlKey = `data-${confKey.toLowerCase().replace(/_/g, '-')}`;
      const attrValue = configuration[confKey];
      if (typeof attrValue === 'string') {
        window.document.body.setAttribute(htmlKey, attrValue);
      } else {
        window.document.body.removeAttribute(htmlKey);
      }
    }
  }

  /**
   * Read the configuration values from the BODY element.
   *
   * @param {Window} window Reference to the current window.
   * @returns {ConfigurationValues} Configuration values found on the element..
   */
  public static getBodyConfiguration(window: Window): ConfigurationValues {
    const values: ConfigurationValues = {};
    const configKeys = getEnumMembers(ConfigurationKeys);

    for (const confKey of configKeys) {
      const htmlKey = `data-${camelCaseToKebabCase(confKey)}`;
      const objectKey = (ConfigurationKeys as any)[confKey] as string;
      values[objectKey] = window.document.body.getAttribute(htmlKey);
    }

    return values;
  }
}
