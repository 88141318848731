import { Result } from '../../utilities/index';
import { LengthUnit, SpeedUnit, UnitSystem, VolumeUnit, WeightUnit } from '../contracts/index';

export class MeasureUnitParsing {

  private static parseUnitSystemInternal(unitSystemString: string): UnitSystem | null {
    switch (unitSystemString.toLowerCase()) {
      case 'metric':
        return UnitSystem.Metric;
      case 'imperial':
      case 'imperialuk':
        return UnitSystem.ImperialUnitedKingdom;
      case 'imperialus':
        return UnitSystem.ImperialUnitedStates;
      default:
        return null;
    }
  }

  public static parseUnitSystem(unitSystemString: string): UnitSystem {
    if (!unitSystemString)
      throw new Error('You need to provide a name for the unit system!');

    const result = MeasureUnitParsing.parseUnitSystemInternal(unitSystemString);
    if (result === null) {
      throw new Error(`The unit system named ${unitSystemString} is not supported yet!`);
    }
    return result;
  }

  public static tryParseUnitSystem(unitSystemString: string): Result<UnitSystem> {
    const unitSystem = MeasureUnitParsing.parseUnitSystemInternal(unitSystemString);
    const result = new Result<UnitSystem>();

    if (unitSystem === null) {
      result.value = UnitSystem.Metric;
      result.error = Error(`The unit system named ${unitSystemString} is not supported yet!`);
    } else {
      result.value = unitSystem;
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private static parseLengthUnitInternal(original: string, _system: UnitSystem): LengthUnit | null {
    switch (original.toLowerCase()) {
      case 'millimeter':
      case 'mm':
        return LengthUnit.Millimeter;
      case 'centimeter':
      case 'cm':
        return LengthUnit.Centimeter;
      case 'decimeter':
      case 'dm':
        return LengthUnit.Decimeter;
      case 'meter':
      case 'm':
        return LengthUnit.Meter;
      case 'inch':
      case 'in':
        return LengthUnit.Inch;
      case 'feet':
      case 'ft':
      case 'foot':
        return LengthUnit.Feet;
      case 'yard':
      case 'yd':
        return LengthUnit.Yard;
      default:
        return null;
    }
  }

  public static parseLengthUnit(original: string, system: UnitSystem = UnitSystem.Metric): LengthUnit {
    const unit = MeasureUnitParsing.parseLengthUnitInternal(original, system);
    if (unit === null) {
      throw new Error(`${original} is not an recognizable length unit value.`);
    }
    return unit;
  }

  public static tryParseLengthUnit(original: string, system: UnitSystem = UnitSystem.Metric): Result<LengthUnit> {
    const unit = MeasureUnitParsing.parseLengthUnitInternal(original, system);
    const res = new Result<LengthUnit>();
    if (unit === null) {
      res.value = LengthUnit.Meter;
      res.error = new Error(`${original} is not an recognizable length unit value.`);
    } else {
      res.value = unit;
    }
    return res;
  }

  private static parseVolumeUnitInternal(original: string, system: UnitSystem): VolumeUnit | null {
    switch (original.toLowerCase()) {
      case 'mm3':
        return VolumeUnit.CubicMillimeter;
      case 'cm3':
        return VolumeUnit.CubicCentimeter;
      case 'dm3':
        return VolumeUnit.CubicDecimeter;
      case 'in3':
        return VolumeUnit.CubicInch;
      case 'ft3':
        return VolumeUnit.CubicFoot;
      case 'l':
      case 'liter':
        return VolumeUnit.Liter;
      case 'm3':
        return VolumeUnit.CubicMeter;
      case 'ounce':
      case 'fl oz':
        return system === UnitSystem.ImperialUnitedStates
          ? VolumeUnit.OunceUnitedStates
          : VolumeUnit.OunceUnitedKingdom;
      case 'pint':
      case 'pt':
        return system === UnitSystem.ImperialUnitedStates
          ? VolumeUnit.PintUnitedStates
          : VolumeUnit.PintUnitedKingdom;
      case 'gallon':
      case 'gal':
        return system === UnitSystem.ImperialUnitedStates
          ? VolumeUnit.GallonUnitedStates
          : VolumeUnit.GallonUnitedKingdom;
      default:
        return null;
    }
  }

  public static parseVolumeUnit(original: string, system: UnitSystem = UnitSystem.Metric): VolumeUnit {
    const unit = MeasureUnitParsing.parseVolumeUnitInternal(original, system);
    if (unit === null) {
      throw new Error(`${original} is not an recognizable volume unit value.`);
    }
    return unit;
  }

  public static tryParseVolumeUnit(original: string, system: UnitSystem = UnitSystem.Metric): Result<VolumeUnit> {
    const unit = MeasureUnitParsing.parseVolumeUnitInternal(original, system);
    const res = new Result<VolumeUnit>();
    if (unit === null) {
      res.value = VolumeUnit.CubicMillimeter;
      res.error = new Error(`${original} is not an recognizable volume unit value.`);
    } else {
      res.value = unit;
    }
    return res;
  }

  private static parseWeightUnitInternal(original: string, system: UnitSystem): WeightUnit | null {
    switch (original.toLowerCase()) {
      case 'gram':
      case 'g':
        return WeightUnit.Gram;
      case 'kilogram':
      case 'kg':
        return WeightUnit.Kilogram;
      case 'ton':
      case 't':
        return WeightUnit.Ton;
      case 'pound':
      case 'lb':
        return WeightUnit.Pound;
      case 'ounce':
      case 'oz':
        return WeightUnit.Ounce;
      case 'stone':
      case 'st':
        return system === UnitSystem.ImperialUnitedStates
          ? WeightUnit.StoneUnitedStates
          : WeightUnit.StoneUnitedKingdom;
      default:
        return null;
    }
  }

  public static parseWeightUnit(original: string, system: UnitSystem = UnitSystem.Metric): WeightUnit {
    const unit = MeasureUnitParsing.parseWeightUnitInternal(original, system);
    if (unit === null) {
      throw new Error(`${original} is not an recognizable weight unit value.`);
    }
    return unit;
  }

  public static tryParseWeightUnit(original: string, system: UnitSystem = UnitSystem.Metric): Result<WeightUnit> {
    const unit = MeasureUnitParsing.parseWeightUnitInternal(original, system);
    const res = new Result<WeightUnit>();
    if (unit === null) {
      res.value = WeightUnit.Gram;
      res.error = new Error(`${original} is not an recognizable weight unit value.`);
    } else {
      res.value = unit;
    }
    return res;
  }

  private static parseSpeedUnitInternal(original: string, _system: UnitSystem): SpeedUnit | null {
    switch (original.toLowerCase()) {
      case 'meterspersecond':
        return SpeedUnit.MetersPerSecond;
      case 'kilometersperhour':
        return SpeedUnit.KilometersPerHour;
      case 'milesperhour':
        return SpeedUnit.MilesPerHour;
      default:
        return null;
    }
  }

  public static parseSpeedUnit(original: string, system: UnitSystem = UnitSystem.Metric): SpeedUnit {
    const unit = MeasureUnitParsing.parseSpeedUnitInternal(original, system);
    if (unit === null) {
      throw new Error(`${original} is not an recognizable speed unit value.`);
    }
    return unit;
  }

  public static tryParseSpeedUnit(original: string, system: UnitSystem = UnitSystem.Metric): Result<SpeedUnit> {
    const unit = MeasureUnitParsing.parseSpeedUnitInternal(original, system);
    const res = new Result<SpeedUnit>();
    if (unit === null) {
      res.value = SpeedUnit.KilometersPerHour;
      res.error = new Error(`${original} is not an recognizable speed unit value.`);
    } else {
      res.value = unit;
    }
    return res;
  }
}
