/* eslint-disable jsdoc/newline-after-description */
import { DateTime } from 'luxon';
import { IDateTimeManager, IDateTimeManagerOptions } from '../contracts';
import { mapDateTimeFormatToInternal } from './dateTimeManagerInternals';

export class DateTimeManager implements IDateTimeManager {

  private _options!: IDateTimeManagerOptions;

  constructor(options: IDateTimeManagerOptions) {
    this._options = options;
  }

  public refreshOptions(options: IDateTimeManagerOptions): void {
    this._options = options;
  }

  /**
   * It uses the timeZone provided in the options opf the DateTimeManager if available
   * If not, it falls back to the local time zone of the machine
   * @param date
   * @returns Date formatted
   */
  public getDateTimeOffsetString(date: string): string {
    return DateTime.fromISO(date, { zone: this._options.timeZone, setZone: true }).toString();
  }

  public getUtcDate(dateTimeOffset: string): Date {
    return DateTime.fromISO(dateTimeOffset, { zone: 'Etc/UTC', setZone: true }).toJSDate();
  }

  public asDateString(dateTimeOffset: string, timeZone?: string | boolean | undefined): string {
    return this._getDate(dateTimeOffset, timeZone).toFormat(this._options.dateDisplayPattern);
  }
  public asTimeString(dateTimeOffset: string, timeZone?: string | boolean | undefined): string {
    return this._getDate(dateTimeOffset, timeZone).toFormat(this._options.timeDisplayPattern);
  }
  public asDateTimeString(dateTimeOffset: string, timeZone?: string | boolean | undefined): string {
    return this._getDate(dateTimeOffset, timeZone).toFormat(this._options.dateTimeDisplayPattern);
  }
  public asCustomString(dateTimeOffset: string, format: string, timeZone?: string | boolean | undefined): string {
    return this._getDate(dateTimeOffset, timeZone).toFormat(mapDateTimeFormatToInternal(format));
  }

  public getTimeZoneOffset(dateTimeOffset: string): number {
    return DateTime.fromISO(dateTimeOffset, { zone: 'Etc/UTC', setZone: true }).offset;
  }

  /**
   * Checks if dateTimeOffset is today accounting for users timezone
   * @param dateTimeOffset
   * @returns
   */
  public isToday(dateTimeOffset: string): boolean {
    const dt = DateTime.fromISO(dateTimeOffset).setZone('Etc/UTC');
    const today = this.getToday();
    return dt.hasSame(today, 'year')
      && dt.hasSame(today, 'month')
      && dt.hasSame(today, 'day');
  }

  /**
   * Checks if dateTimeOffset is tomorrow accounting for users timezone
   * @param _dateTimeOffset
   * @returns
   */
  public isTomorrow(dateTimeOffset: string): boolean {
    const dt = DateTime.fromISO(dateTimeOffset).setZone('Etc/UTC');
    const tomorrow = this.getTomorrow();
    return dt.hasSame(tomorrow, 'year')
      && dt.hasSame(tomorrow, 'month')
      && dt.hasSame(tomorrow, 'day');
  }

  /**
   * Checks if 2 dateTimeOffsets point to the same calendar day, accounting for users timezone
   * @param dateTimeOffset1
   * @param dateTimeOffset2
   * @returns
   */
  public isSameDate(dateTimeOffset1: string, dateTimeOffset2: string): boolean {
    const dt1 = DateTime.fromISO(dateTimeOffset1, { zone: this._options.timeZone });
    const dt2 = DateTime.fromISO(dateTimeOffset2, { zone: this._options.timeZone });
    return dt1.hasSame(dt2, 'day');
  }

  private _getDate(date: string, timeZone?: string | boolean | undefined): DateTime {
    const d = DateTime.fromISO(date, { zone: 'Etc/UTC', setZone: true }).setLocale(this._options.locale);

    if (typeof timeZone === 'undefined' || timeZone === false) {
      return d;
    }

    const tz = timeZone === true || !timeZone ? this._options.timeZone : timeZone;
    return d.setZone(tz);
  }

  private getToday(): DateTime {
    return DateTime.now().setZone('Etc/UTC');
  }

  private getTomorrow(): DateTime {
    return DateTime.now().setZone('Etc/UTC').plus({ days: 1 });
  }
}
