import { INavbar } from '../../../../src/auxiliary-components/contracts/navigation/iNavbar';
import { BusMessage, IListener, IUser } from '../../../core-components/contracts/index';
import { ConfigurationKeys, MetadataKeys, IHostApplication } from '../../../host/index';
import { INavigateTo, Types as MT } from '../../../messages/index';
import { KeyValuePair } from '../../../utilities/index';
import { IMenuItem } from './iMenuItems';

/**
 * Navigation menu configuration options.
 */
export interface INavbarOptions {
  /**
   * Classes to add to the root navigation element.
   */
  rootElementCssClasses: string[];
  /**
   * Class to add to the "active" menu items.
   */
  activeCssClass: string;
  /**
   * Class to add to in order to hide an item.
   */
  hiddenCssClass: string;
  /**
   * The name of the message that will toggle the navigation menu visibility.
   */
  visibilityToggleMessage: string;
}

/**
 * Base class for implementing navigation bar components.
 */
export abstract class Navbar implements INavbar {
  private _disposed = false;
  private _navEl: HTMLElement | null = null;
  protected readonly host: IHostApplication;
  protected messageListeners: KeyValuePair<string, IListener>[] = [];
  protected window: Window;
  protected user: IUser | null = null;
  protected options: INavbarOptions;

  /**
   * Base class for implementing a navbar component.
   *
   * @param {Window} window A window reference.
   * @param {IHostApplication} host Host application reference.
   * @param {HTMLElement} el The navigation bar parent element.
   * @param {INavbarOptions} options The navigation bar options.
   */
  constructor(window: Window, host: IHostApplication, el: HTMLElement, options: INavbarOptions) {
    this.window = window;
    this.host = host;
    this.options = options;
    this.appendNavElement(el);
  }

  /**
   * Adds message bus event listeners.
   */
  private _addMessageBusListeners() {
    this.messageListeners.forEach(f => {
      this.host.messageBus.subscribe(f.key, f.value);
    });
  }

  /**
   * Removes message bus event listeners.
   */
  private _removeMessageBusListeners() {
    this.messageListeners.forEach(f => {
      this.host.messageBus.unsubscribe(f.key, f.value);
    });
  }

  /**
   * The navigation bar element.
   */
  protected set navEl(el: HTMLElement | null) {
    this._navEl = el;
  }
  /**
   * The navigation bar element.
   */
  protected get navEl(): HTMLElement {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._navEl!;
  }

  /**
   * Append the navigation element.
   *
   * @param {HTMLElement} el The parent for the navigation element.
   */
  protected appendNavElement(el: HTMLElement): void {
    this.navEl = this.window.document.createElement('nav');
    this.navEl.classList.add(...this.options.rootElementCssClasses);
    this.navEl.setAttribute('role', 'navigation');
    el.appendChild(this.navEl);
  }

  /**
   * Remove the navigation component.
   */
  protected removeNavElement(): void {
    if (this.navEl) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.navEl.parentElement!.removeChild(this.navEl);
    }
  }

  /**
   * When derived implements the core logic for creating the navigation component.
   */
  protected abstract initializeCore(): Promise<void>;
  /**
   * When derived implements the core logic for cleaning-up the navigation component.
   */
  protected abstract disposeCore(): Promise<void>;

  /**
   * When derived implements the logic to render the navigation component.
   */
  protected abstract render(): Promise<void>;

  /**
   * Clean up the current active links and set the current one as active.
   */
  protected abstract refreshActiveLinks(): void;

  /**
   * Returns if the given url is part of the current app or not.
   *
   * @param {string} navigationUrl the url to be tested
   * @returns {boolean}
   */
  protected isCurrentApp(navigationUrl: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (navigationUrl.indexOf(this.host.configuration[ConfigurationKeys.appRootPathAbsolute]!) === 0) {
      return true; // same app
    } else {
      return false; // different app
    }
  }

  /**
   * Return the URL relative to the current application.
   *
   * @param {string} navigationUrl the url to be tested
   * @returns {boolean}
   */
  protected getCurrentAppRelativeUrl(navigationUrl: string): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let relativeUrl = navigationUrl.substring(this.host.configuration[ConfigurationKeys.appRootPathAbsolute]!.length);
    relativeUrl = relativeUrl.replace(/^\//gi, '');
    relativeUrl = '/' + relativeUrl;
    return relativeUrl;
  }

  /**
   * Publishes CMN_NAVIGATION_GO_TO command for navigating within the same app.
   *
   * @param {Event} event The click event.
   */
  protected clickLinksHandler(event: Event): void {
    const url: string = this.getCurrentAppRelativeUrl((event.target as HTMLAnchorElement).href);
    /* publish msg for navigation in the same app. */
    event.preventDefault();
    this.host.messageBus.publish(
      new BusMessage<INavigateTo>(MT.C.CMN_NAVIGATION_GO_TO, { url: url })
    );
  }

  /**
   * Toggles the visibility of the main navbar element.
   *
   * @param {boolean} isVisible Should the navigation bar be visible?
   */
  protected toggle(isVisible = false): void {
    if (this.navEl) {
      if (isVisible) {
        this.navEl.classList.remove(this.options.hiddenCssClass);
      } else {
        this.navEl.classList.add(this.options.hiddenCssClass);
      }
    }
  }

  /**
   * Updates the reference to the user.
   *
   * @param {IUser | null} user The new updated user.
   */
  protected updateUser(user: IUser | null): void {
    this.user = user ? user : null;
  }

  /**
   * Removes the event handlers and recreate the
   * content with the updated data.
   */
  protected async refresh(): Promise<void> {
    await this.render();
    this.refreshActiveLinks();
  }

  /**
   * Returns true is the menu item should be marked as active.
   *
   * @param {String} currentLocation The current window location.
   * @param {String} menuUrl The current menu item URL.
   */
  protected isMenuItemActive(currentLocation: string, menuUrl: string): boolean {
    if (!this.isCurrentApp(menuUrl))
      return false;

    let link = this.getCurrentAppRelativeUrl(menuUrl).trim().toLowerCase();

    let currentUrl = this.getCurrentAppRelativeUrl(currentLocation).trim().toLowerCase();
    currentUrl = (currentUrl.split('?')[0]).split('#')[0];

    // Accept only the exact url or '/index', '/index.htm', '/index.html', '/index.some-extension' in case current relative url is '/'
    if (currentUrl === '/') {
      /* remove the index from the link to check if it matches the rest */
      const pattersToRemove = ['index', 'index.'];
      for (const pattern of pattersToRemove) {
        if (link.indexOf(pattern) > -1) {
          link = link.substring(0, link.indexOf(pattern));
        }
      }

      return currentUrl === link;
    }

    if (currentUrl.indexOf(link) === 0) {
      return true;
    }

    return false;
  }


  /**
   * Returns true if the current menu item or any of it's children are related to the current application.
   *
   * @param {IMenuItem} item The menu item.
   */
  protected isMenuItemRelatedToApp(item: IMenuItem): boolean {
    const aliases: string[] = this.host.coreApplicationMetadata[MetadataKeys.appNameAliases] ?? [];
    if (item.app === this.host.configuration[ConfigurationKeys.appName] || aliases.indexOf(item.app) > -1) {
      return true;
    }

    if (item.items && item.items.length > 0) {
      for (const child of item.items) {
        if (this.isMenuItemRelatedToApp(child)) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Initialize the navigation component.
   */
  public async initialize(): Promise<void> {
    this.toggle(false);

    if (this.host.isStandAlone) {
      this.messageListeners.push({
        key: this.options.visibilityToggleMessage,
        value: (_name, messageData: BusMessage<boolean>) => {
          this.toggle(messageData.data);
        }
      });
      this.messageListeners.push({
        key: MT.E.CMN_USER_CHANGED,
        value: (_name, messageData: BusMessage<IUser | null>) => {
          this.updateUser(messageData.data);
          void this.refresh();
        }
      });
      this.messageListeners.push({
        key: MT.E.CMN_NAVIGATION_CHANGED,
        value: () => {
          this.refreshActiveLinks();
        }
      });
    }

    const user = await this.host.userManager.getUser();
    this.updateUser(user);

    await this.initializeCore();
    await this.render();
    this.refreshActiveLinks();
    this._addMessageBusListeners();
  }

  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    if (this._disposed)
      return;

    this._disposed = true;
    await this.disposeCore();
    this._removeMessageBusListeners();
    this.removeNavElement();
  }
}
