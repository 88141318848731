/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { MainNavbar, SideNavbar } from '../../../src/auxiliary-components/implementations/index';
import { KeyValuePair } from '../../../src/utilities';
import { Types as MT } from '../../../src/messages/index';
import { INavbar } from '../../auxiliary-components/index';
import { BusMessage, IListener } from '../../core-components/index';
import { IHostApplication, ILayoutManager } from '../contracts/index';

/**
 * The options for the layout manger.
 */
interface ILayoutManagerOptions {
  /**
   * Classes for the container wrapper.
   */
  containerClasses: string[];
  /**
   * Classes for the rows inside the container wrapper.
   */
  containerRowClasses: string[];
  /**
   * Classes for the container wrapper when embedded.
   */
  containerClassesEmbedded: string[];
  /**
   * Classes for the side menu when visible.
   */
  sideMenuVisibleClasses: string[];
  /**
   * Classes for the side menu when not visible.
   */
  sideMenuHiddenClasses: string[];
  /**
   * Classes for the content data when the side menu is visible.
   */
  contentDataClasses: string[];
  /**
   * Classes for the content data when the side menu is not visible.
   */
  contentDataFullWidthClasses: string[];
}

/**
 * @inheritdoc
 */
export class LayoutManager implements ILayoutManager {
  private _disposed = false;
  private _headerEl: HTMLElement | null = null;
  private _containerEl: HTMLElement | null = null;
  private _sideMenuEl: HTMLElement | null = null;
  private _dataEl: HTMLElement | null = null;
  private _messageListeners: KeyValuePair<string, IListener>[] = [];

  private _mainNavbar: INavbar | null = null;
  private _sideNavbar: INavbar | null = null;

  protected window: Window;
  protected host: IHostApplication;
  protected options: ILayoutManagerOptions = {
    containerClasses: ['container-fluid', 'content'],
    containerRowClasses: ['row', 'p-0', 'm-0'],
    containerClassesEmbedded: ['p-0', 'm-0'],
    sideMenuHiddenClasses: ['content-menu', 'd-none', 'p-0', 'm-0'],
    sideMenuVisibleClasses: ['content-menu', 'col-12', 'col-sm-5', 'col-md-4', 'col-lg-3', 'col-xl-2'],
    contentDataClasses: ['content-data', 'col-12', 'col-sm-7', 'col-md-8', 'col-lg-9', 'col-xl-10'],
    contentDataFullWidthClasses: ['content-data', 'col-12', 'p-0', 'm-0']
  };


  /**
   * Constructor.
   *
   * @param {Window} window The current window reference.
   * @param {IHostApplication} host The current host application instance.
   */
  constructor(window: Window, host: IHostApplication) {
    this.window = window;
    this.host = host;
  }

  private _buildDocumentStructure(): void {
    const frag = this.window.document.createDocumentFragment();

    this._headerEl = this.window.document.createElement('header');
    frag.appendChild(this._headerEl);

    this._containerEl = this.window.document.createElement('div');
    this._containerEl.classList.add(...this.options.containerClasses);
    if (!this.host.isStandAlone) {
      this._containerEl.classList.add(...this.options.containerClassesEmbedded);
    }
    const rowEl = this.window.document.createElement('div');
    rowEl.classList.add(...this.options.containerRowClasses);
    this._containerEl.appendChild(rowEl);
    frag.appendChild(this._containerEl);

    this._sideMenuEl = this.window.document.createElement('div');
    this._sideMenuEl.classList.add(...this.options.sideMenuHiddenClasses);
    rowEl.appendChild(this._sideMenuEl);

    this._dataEl = this.window.document.createElement('div');
    this._dataEl.classList.add(...this.options.contentDataFullWidthClasses);
    rowEl.appendChild(this._dataEl);

    this.window.document.body.insertBefore(frag, this.window.document.body.firstChild);
  }

  private _attachMessageListeners(): void {
    if (this.host.isStandAlone) {
      this._messageListeners.push({
        key: MT.C.CMN_SIDE_NAVBAR_VISIBILITY_TOGGLE,
        value: (_name, message: BusMessage<boolean>) => {
          this._toggleSideMenu(message.data);
        }
      });
    }

    for (const listener of this._messageListeners) {
      this.host.messageBus.subscribe(listener.key, listener.value);
    }
  }

  private _detachMessageListeners(): void {
    let listener = this._messageListeners.pop();
    while (listener) {
      this.host.messageBus.unsubscribe(listener.key, listener.value);
      listener = this._messageListeners.pop();
    }
  }

  private _destroyDocumentStructure(): void {
    if (this._containerEl) {
      this._containerEl.parentElement!.removeChild(this._containerEl);
    }

    if (this._headerEl) {
      this._headerEl.parentElement!.removeChild(this._headerEl);
    }
  }

  private _toggleSideMenu(isVisible: boolean): void {
    let sideElClassesToRemove: string[] = [];
    let sideElClassesToAdd: string[] = [];
    let dataElClassesToRemove: string[] = [];
    let dataElClassesToAdd: string[] = [];

    if (isVisible) {
      sideElClassesToAdd = this.options.sideMenuVisibleClasses;
      sideElClassesToRemove = this.options.sideMenuHiddenClasses;
      dataElClassesToAdd = this.options.contentDataClasses;
      dataElClassesToRemove = this.options.contentDataFullWidthClasses;
    } else {
      sideElClassesToAdd = this.options.sideMenuHiddenClasses;
      sideElClassesToRemove = this.options.sideMenuVisibleClasses;
      dataElClassesToAdd = this.options.contentDataFullWidthClasses;
      dataElClassesToRemove = this.options.contentDataClasses;
    }

    this._sideMenuEl!.classList.remove(...sideElClassesToRemove);
    this._dataEl!.classList.remove(...dataElClassesToRemove);
    this._sideMenuEl!.classList.add(...sideElClassesToAdd);
    this._dataEl!.classList.add(...dataElClassesToAdd);
  }

  /**
   * Resolve the main navigation bar component.
   */
  protected resolveMainNavbar(el: HTMLElement): INavbar {
    return new MainNavbar(this.window, this.host, el);
  }

  /**
   * Resolve the side navigation component.
   */
  protected resolveSideNavbar(el: HTMLElement): INavbar {
    return new SideNavbar(this.window, this.host, el);
  }

  /**
   * @inheritdoc
   */
  public async initialize(): Promise<ILayoutManager> {

    this._buildDocumentStructure();
    this._attachMessageListeners();

    this._mainNavbar = this.resolveMainNavbar(this._headerEl!);
    this._sideNavbar = this.resolveSideNavbar(this._sideMenuEl!);


    await Promise.all([
      this._mainNavbar.initialize(),
      this._sideNavbar.initialize()
    ]);
    return this;
  }


  /**
   * @inheritdoc
   */
  public getApplicationElement(): HTMLElement {
    if (!this._dataEl)
      throw new Error('Manager is not initialized.');

    if (this._dataEl.firstChild)
      return this._dataEl.firstChild as HTMLElement;

    const app = this.window.document.createElement('div');
    this._dataEl.appendChild(app);
    return app;
  }

  /**
   * @inheritdoc
   */
  public getMainNavbar(): INavbar {
    if (!this._mainNavbar) {
      throw new Error('Layout manager not initialized.');
    }
    return this._mainNavbar;
  }

  /**
   * @inheritdoc
   */
  public getSideNavbar(): INavbar {
    if (!this._sideNavbar) {
      throw new Error('Layout manager not initialized.');
    }
    return this._sideNavbar;
  }


  /**
   * @inheritdoc
   */
  public async dispose(): Promise<void> {
    if (this._disposed)
      return;

    this._disposed = true;

    const toDispose: Promise<void>[] = [];
    if (this._mainNavbar) {
      toDispose.push(this._mainNavbar.dispose());
    }
    if (this._sideNavbar) {
      toDispose.push(this._sideNavbar.dispose());
    }

    await Promise.resolve(toDispose);

    this._mainNavbar = null;
    this._sideNavbar = null;

    this._detachMessageListeners();
    this._destroyDocumentStructure();
  }

}
