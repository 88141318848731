/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { BackgroundWorker, BusMessage, IMessageBus, II18nManager } from '../../../core-components';
import { Logger, LogLevel } from '@validide/logger';
import { sendAsync } from '../../../utilities';
import { IClearNotifications, IShowNotification } from '../../../messages/index';
import { i18N } from '../../../constants';
import { Types } from '../../../messages/index';
import { DOM_CONST } from '../index';
export class DateSkewChecker extends BackgroundWorker {

  private readonly _url: string;
  private readonly _maxSkew: number;
  private readonly _messageBus: IMessageBus;
  private readonly _i18nManager: II18nManager;
  private readonly _detailsUrl: string;
  private readonly _logger: Logger;
  private _isSkewed: boolean;
  private _lastOnFocusCheck: number;
  private _onFocusHandler: EventListener;
  private _onFocusCheckInterval: number;
  private _initCoreTimeout: number;
  /**
   * Constructor.
   *
   * @param {window} window Operating window object for class
   * @param {string} url Base URL for component
   * @param {II18nManager} i18nManager For translating outputs
   * @param {string} detailsUrl Url for calls for 'details' data
   * @param {IMessageBus} eventBus Message Bus in which error messages are posted to
   * @param {Logger} logger Logger in which operations are written to
   * @param {number} focusCheckInterval Minimum interval in which the focus checks are made, in milliseconds
   * @param {number} checkIntervalMs Interval for automatic checking
   */
  constructor(
    window: Window,
    url: string,
    i18nManager: II18nManager,
    detailsUrl: string,
    eventBus: IMessageBus,
    logger: Logger,
    focusCheckInterval?: number,
    checkIntervalMs?: number
  ) {
    super(window, checkIntervalMs ?? 1 * 60 * 60 * 1000, false); // 1 * 60 * 60 * 1000 = 1 hour(s) * 60 minutes * 60 seconds * 1000 milliseconds
    this._url = url;
    this._maxSkew = 5 * 60; // 5 minute(s) * 60 seconds
    this._i18nManager = i18nManager;
    this._detailsUrl = detailsUrl;
    this._messageBus = eventBus;
    this._logger = logger;
    this._isSkewed = false;
    this._lastOnFocusCheck = 0;
    this._onFocusHandler = this.checkDateTimeOnFocus.bind(this);
    this._onFocusCheckInterval = focusCheckInterval ?? (5 * 60 * 100);
    this._initCoreTimeout = checkIntervalMs ?? 3_000;
  }

  override initializeCore(): Promise<void> {
    this.window.setTimeout(() => {
      const offset = this.getWindowOffset();
      // If the offset is 0 do not show notification, it was not populated by the server.
      this.checkDateTime(offset, offset === 0);
    }, this._initCoreTimeout);
    return super.initializeCore();
  }

  protected async execute(): Promise<void> {
    const result = await sendAsync({
      url: this._url,
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    let success = false;
    let responseObject: any | null = null;
    if (result.request.status === 200) {
      try {
        responseObject = JSON.parse(result.request.responseText);
        success = true;
      } catch (error: any) {
        this._logger.log(LogLevel.Error, '"execute" failed', error as Error);
      }
    }

    if (success) {
      this.checkDateTime(responseObject.OFFSET, false);
    } else {
      this._logger.log(LogLevel.Warning, 'DateSkewChecker failed to get data from server', undefined, {
        'status': result.request.status,
        'statusText': result.request.statusText
      });
    }
  }

  private checkDateTime(offset: number, skipNotification: boolean): void {
    this._isSkewed = this.isSkewed(offset);
    if (this._isSkewed) {
      this.window.removeEventListener('focus', this._onFocusHandler, false);
      this.window.addEventListener('focus', this._onFocusHandler, false);
    }

    const group = 'cmn.dateSkewed';
    if (this._isSkewed) {
      if (skipNotification) {
        return;
      }
      this._messageBus.publish<IShowNotification>(new BusMessage<IShowNotification>(
        Types.C.CMN_NOTIFICATION_SHOW,
        {
          title: this._i18nManager.translate(i18N.CMN_Error),
          body: this._i18nManager.translate(i18N.CMN_DateIsSkewed),
          group: group,
          dismissable: false,
          detailsUrl: this._detailsUrl,
          single: true
        }
      ));
    } else {
      this._messageBus.publish<IClearNotifications>(new BusMessage<IClearNotifications>(
        Types.C.CMN_NOTIFICATION_CLEAR_GROUP,
        {
          group: group
        }
      ));
    }
  }

  private checkDateTimeOnFocus(): void {
    if (!this._isSkewed) {
      this.window.removeEventListener('focus', this._onFocusHandler, false);
      return;
    }

    const now = new Date().getTime();
    if ((now - this._lastOnFocusCheck) < this._onFocusCheckInterval) {
      // Do not check more often than X minutes;
      return;
    }

    this._lastOnFocusCheck = now;
    // Start a new check;
    void this.execute();
  }

  private getWindowOffset(): number {
    const offset = parseFloat(this.window.document.body.getAttribute(DOM_CONST.ATTR_DATE_OFFSET) || '0');
    return offset;
  }

  private isSkewed(offset: number): boolean {
    const localUnixOffset = (new Date()).getTime() / 1000;
    const skew = Math.abs(localUnixOffset - offset);
    return skew > this._maxSkew;
  }
}
