/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LogLevel } from '@validide/logger';
import { INavbar } from '../../index';
import { BusMessage, IUserData } from '../../../core-components/contracts/index';
import { ConfigurationKeys, Functionalities, IHostApplication } from '../../../host/index';
import { CONST } from '../../../index';
import { IShowMicroFrontEnd, Types as MT } from '../../../messages/index';
import { sendAsync } from '../../../utilities/index';
import { IMenuItem, IMenuItemOption } from './iMenuItems';
import { INavbarOptions, Navbar } from './navBar';

interface IMainNavbarData {
  mainMenuItems: IMenuItem[];
  userDropDownMenuItems: IMenuItem[];
}

interface IEventListenerData {
  element: HTMLAnchorElement;
  eventName: string;
  handler: (event: Event) => void;
}

export interface IMainNavbarOptions extends INavbarOptions {
  functionalitiesIcon: { [id: string]: string };
  userDropDownMenuItemsOptions: { [id: string]: IMenuItemOption };
  secondaryMenuCssClasses: string[];
}

export interface IMainNavbar extends INavbar {
  /**
   * Gets the primary nav HTMLElement
   */
  getPrimaryNavEl(): HTMLElement;
  /**
   * Gets the secondary nav HTMLElement
   */
  getSecondaryNavEl(): HTMLElement;
}

/**
 * Main navigation bar.
 */
export class MainNavbar extends Navbar implements IMainNavbar {

  protected _navbarMenuEl: HTMLElement | null = null;
  protected _secNavEl: HTMLElement | null = null;
  protected _secMenuEl: HTMLElement | null = null;

  private _clickLinksHandlerRef: (event: Event, data?: any) => void;
  private _linksWithEventListeners: IEventListenerData[] = [];
  private _navbarData: IMainNavbarData = {
    mainMenuItems: [],
    userDropDownMenuItems: []
  };

  /**
   * @inheritdoc
   */
  public getPrimaryNavEl(): HTMLElement {
    return this.navEl;
  }

  /**
   * @inheritdoc
   */
  public getSecondaryNavEl(): HTMLElement {
    return this._secNavEl!;
  }

  /**
   * Constructor.
   *
   * @param {Window} window A window reference.
   * @param {IHostApplication} host Host application reference.
   * @param {HTMLElement} el The navigation bar parent element.
   */
  constructor(window: Window, host: IHostApplication, el: HTMLElement) {
    super(window, host, el, {
      activeCssClass: 'active',
      hiddenCssClass: 'd-none',
      rootElementCssClasses: ['navbar', 'navbar-primary'],
      visibilityToggleMessage: MT.C.CMN_MAIN_NAVBAR_VISIBILITY_TOGGLE,
      functionalitiesIcon: {
        'HomeGroup_4900': 'glyphicons glyphicons-home',
        'HomeDash_5000': 'glyphicons glyphicons-home',
        'WEBUI_Home': 'glyphicons glyphicons-home',
        'WEBUI_DashboardRoot': 'glyphicons glyphicons-home'
      },
      userDropDownMenuItemsOptions: {
        [Functionalities.UserProfile]: {
          icon: 'fa-user-circle-o',
          label: CONST.i18N.CMN_Profile,
          callback: (e: Event) => {
            e.preventDefault();
            this.host.messageBus.publish<IShowMicroFrontEnd>(new BusMessage<IShowMicroFrontEnd>(
              MT.C.CMN_MFE_SHOW,
              {
                url: (e.target as HTMLAnchorElement).href,
                isModal: true
              }
            ));
          }
        },
        [Functionalities.WhatNew]: {
          icon: 'fa-rss',
          label: CONST.i18N.CMN_WhatsNew,
          callback: null
        },
        [Functionalities.Settings]: {
          icon: 'fa-gear',
          label: CONST.i18N.CMN_Settings,
          callback: null
        },
        [Functionalities.Help]: {
          icon: 'fa-support',
          label: CONST.i18N.CMN_Help,
          callback: null
        },
        [Functionalities.Logout]: {
          icon: 'fa-lock',
          label: CONST.i18N.CMN_LogOut,
          callback: (e: Event) => {
            e.preventDefault();
            this.host.messageBus.publish(new BusMessage<void>(MT.C.CMN_USER_SIGNOUT, undefined));
          }
        }
      },
      secondaryMenuCssClasses: ['navbar', 'navbar-secondary']
    } as IMainNavbarOptions);

    this._appendSecNavElement(el);
    this._clickLinksHandlerRef = e => { this.clickLinksHandler(e); };
  }

  /**
   * Fetch the top menu entries and use the relative url for internal links.
   *
   * @returns { [key: string]: SettingsMenuEntry[] } the mainMenuItems
   */
  protected async _getMainMenuItems(): Promise<IMenuItem[]> {
    if (!this.user || !this.user.accessToken || this.user.expired) {
      return [];
    }

    const hostname = encodeURIComponent(this.window.location.hostname);
    const remoteResponse = await sendAsync({
      url: this.host.buildUrl(`${this.host.configuration[ConfigurationKeys.mainNavMenuUrl]!}?domainUri=${hostname}`),
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.user.accessToken}`
      }
    });

    if (remoteResponse.request.status !== 200) {
      this.host.loggerFactory.getLogger('MainNavbar').log(LogLevel.Error, 'Failed to load top menu entries.', undefined, { 'HTTP_STATUS': remoteResponse.request.status});
      return [];
    }

    return JSON.parse(remoteResponse.request.responseText);
  }

  /**
   * Fetch the user navigation menu.
   *
   * @returns {Promise<IMenuItem[]>} the user navigation menu
   */
  protected async _getUserDropDownMenuItems(): Promise<IMenuItem[]> {
    if (!this.user || !this.user.accessToken || this.user.expired) {
      return [];
    }

    const hostname = encodeURIComponent(this.window.location.hostname);
    const remoteResponse = await sendAsync({
      url: this.host.buildUrl(`${this.host.configuration[ConfigurationKeys.userDropDownMenuUrl]!}?domainUri=${hostname}`),
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.user.accessToken}`
      }
    });

    if (remoteResponse.request.status !== 200) {
      this.host.loggerFactory.getLogger('MainNavbar').log(LogLevel.Error, 'Failed to load user navigation menu.', undefined, { 'HTTP_STATUS': remoteResponse.request.status});
      return [];
    }

    return JSON.parse(remoteResponse.request.responseText);
  }

  /**
   * Creates the navbar menu element.
   *
   * @returns {HTMLElement}
   */
  private _createMainNavMenu(): void {
    const mainNavMenu: HTMLElement = this.window.document.createElement('ul');
    mainNavMenu.className = 'nav navbar-nav navbar-menu';
    this._navbarMenuEl = mainNavMenu;

    const secondaryMenu = this.window.document.createElement('ul');
    secondaryMenu.className = 'nav navbar-nav';
    secondaryMenu.setAttribute('role', 'tablist');
    this._secMenuEl = secondaryMenu;

    if (this._navbarData.mainMenuItems && this._navbarData.mainMenuItems.length > 0) {
      this._navbarData.mainMenuItems.forEach(entry => {
        const menuItem = this._createMenuEntry(entry);
        const linkEl = menuItem.querySelector('a')!;

        const functionalityIcon = (this.options as IMainNavbarOptions).functionalitiesIcon[entry.functionalityId];
        if (functionalityIcon) {
          linkEl.innerText = '';
          const iconEl = this.window.document.createElement('span');
          iconEl.className = functionalityIcon;
          linkEl.appendChild(iconEl);
        }

        mainNavMenu.appendChild(menuItem);

        if (entry.items && this.isMenuItemRelatedToApp(entry)) {
          for (const childEntry of entry.items) {
            const childLi = this._createMenuEntry(childEntry, entry);
            childLi.classList.add(this.options.hiddenCssClass);
            this._secMenuEl!.appendChild(childLi);
          }
        }
      });
    }

    this.navEl.appendChild(this._navbarMenuEl);
    this._secNavEl!.appendChild(this._secMenuEl);
  }

  private _createMenuEntry(entry: IMenuItem, parentEntry?: IMenuItem) {
    const li = this.window.document.createElement('li');
    li.className = 'nav-item';
    li.setAttribute('data-functionality-id', entry.functionalityId);
    if (parentEntry) {
      li.setAttribute('data-parent-functionality-id', parentEntry.functionalityId);
    }
    const link = this.window.document.createElement('a');
    link.className = 'nav-link';
    link.title = entry.label;
    link.href = this.host.buildUrl(entry.url);
    link.rel = 'nofollow noopener noreferrer';
    link.innerText = entry.label;

    if (this.isCurrentApp(link.href)) {
      this._addEventListener(link, 'click', this._clickLinksHandlerRef);
    }

    li.appendChild(link);
    return li;
  }

  private _appendUserMenuPublic(mainNavUser: any) {
    const listItem: HTMLElement = this.window.document.createElement('li');
    listItem.className = 'nav-item';

    const linkEl: HTMLAnchorElement = this.window.document.createElement('a');
    linkEl.className = 'nav-link';
    linkEl.target = '_blank';
    linkEl.href = this.host.configuration[ConfigurationKeys.supportUrl]!;
    linkEl.rel = 'nofollow noopener noreferrer';

    const iconEl: HTMLElement = this.window.document.createElement('span');
    iconEl.className = 'fa fa-support mr-1';
    iconEl.setAttribute('aria-hidden', 'true');
    const textNode = document.createTextNode(`${this.host.i18nManager.translate(CONST.i18N.CMN_Help)}`);

    linkEl.appendChild(iconEl);
    linkEl.appendChild(textNode);
    listItem.appendChild(linkEl);
    mainNavUser.appendChild(listItem);
  }

  private _appendUserMenu(data: IMainNavbarData, mainNavUser: HTMLElement, user: IUserData) {
    const listItem: HTMLElement = this.window.document.createElement('li');
    listItem.className = 'nav-item';

    const linkEl: HTMLAnchorElement = this.window.document.createElement('a');
    linkEl.className = 'nav-link dropdown-toggle';
    linkEl.id = 'user-dropdown';
    linkEl.href = 'javascript:void(0);';
    linkEl.setAttribute('data-toggle', 'dropdown');
    linkEl.setAttribute('aria-haspopup', 'true');
    linkEl.setAttribute('aria-expanded', 'false');

    const iconEl: HTMLElement = this.window.document.createElement('span');
    iconEl.className = 'fa fa-user mr-1';
    iconEl.setAttribute('aria-hidden', 'true');
    linkEl.appendChild(iconEl);

    const usernameNode = document.createTextNode(user.profile.name || user.profile.sub);
    linkEl.appendChild(usernameNode);

    const divEl: HTMLElement = this.window.document.createElement('div');
    divEl.className = 'dropdown-menu dropdown-menu-right nav-dropdown';
    divEl.setAttribute('aria-labelledby', linkEl.id);

    if (data.userDropDownMenuItems && data.userDropDownMenuItems.length > 0) {
      data.userDropDownMenuItems.forEach(item => {
        const dropdownItem: HTMLAnchorElement = this.window.document.createElement('a');
        dropdownItem.href = this.host.buildUrl(item.url);
        dropdownItem.className = 'dropdown-item';
        dropdownItem.rel = 'nofollow noopener noreferrer';
        const mOption = this._getMenuOption(item.functionalityId);
        const callback = mOption.callback;
        if (callback) {
          this._addEventListener(dropdownItem, 'click', callback);
        } else if (this.isCurrentApp(dropdownItem.href)) {
          this._addEventListener(dropdownItem, 'click', this._clickLinksHandlerRef);
        }

        const spanIconEl: HTMLElement = this.window.document.createElement('span');
        spanIconEl.className = `fa ${mOption.icon} mr-1`;
        spanIconEl.setAttribute('aria-hidden', 'true');

        const textNode = document.createTextNode(`${this.host.i18nManager.translate(mOption.label)}`);
        dropdownItem.appendChild(spanIconEl);
        dropdownItem.appendChild(textNode);

        divEl.appendChild(dropdownItem);
      });

      const dividerEl: HTMLElement = this.window.document.createElement('div');
      dividerEl.className = 'dropdown-divider';
      divEl.appendChild(dividerEl);
    }

    /* Log Out item */
    const logOutItem: HTMLAnchorElement = this.window.document.createElement('a');
    logOutItem.className = 'dropdown-item';
    logOutItem.id = 'logoutButton';
    logOutItem.href = 'javascript:void(0)';
    const logoutOptions = this._getMenuOption(Functionalities.Logout);
    if (logoutOptions.callback) {
      this._addEventListener(logOutItem, 'click', logoutOptions.callback);
    }

    const logOutItemIconEl: HTMLElement = this.window.document.createElement('span');
    logOutItemIconEl.className = 'fa fa-lock mr-1';
    logOutItemIconEl.setAttribute('aria-hidden', 'true');

    const textNodeLogOut = document.createTextNode(`${this.host.i18nManager.translate(CONST.i18N.CMN_LogOut)}`);

    logOutItem.appendChild(logOutItemIconEl);
    logOutItem.appendChild(textNodeLogOut);

    divEl.appendChild(logOutItem);

    listItem.appendChild(linkEl);
    listItem.appendChild(divEl);
    mainNavUser.appendChild(listItem);
  }

  /**
   * Creates the navbar user element.
   *
   * @returns {HTMLElement}
   */
  private _createMainNavUser(): void {
    const mainNavUser: HTMLElement = this.window.document.createElement('ul');
    mainNavUser.className = 'nav navbar-nav navbar-user';

    if (this.user && this.user.profile.sub && !this.user.expired) {
      this._appendUserMenu(this._navbarData, mainNavUser, this.user);
    } else {
      this._appendUserMenuPublic(mainNavUser);
    }

    this.navEl.appendChild(mainNavUser);
  }

  private _getMenuOption(functionalityId: string) {
    return (this.options as IMainNavbarOptions).userDropDownMenuItemsOptions[functionalityId] || { callback: null, icon: 'fa-info', label: functionalityId };
  }

  /**
   * Adds event handler.
   */
  private _addEventListener(element: HTMLAnchorElement, eventName: string, handler: ((event: Event, data?: any) => void)) {
    this._linksWithEventListeners.push({ element: element, eventName: eventName, handler: handler });
    element.addEventListener(eventName, handler);
  }

  /**
   * Removes event handlers.
   */
  private _removeEventListeners() {
    this._linksWithEventListeners.forEach((evListenerData: IEventListenerData) => {
      evListenerData.element.removeEventListener(evListenerData.eventName, evListenerData.handler);
    });

  }

  private _cleanNavContent(): void {
    // Clean previous elements and handlers.
    this._removeEventListeners();
    while (this.navEl.firstChild) {
      this.navEl.removeChild(this.navEl.firstChild);
    }
    while (this._secNavEl!.firstChild) {
      this._secNavEl!.removeChild(this._secNavEl!.firstChild);
    }
  }

  private _appendSecNavElement(el: HTMLElement): void {

    this._secNavEl = this.window.document.createElement('nav');
    this._secNavEl.classList.add(...(this.options as IMainNavbarOptions).secondaryMenuCssClasses);
    this._secNavEl.setAttribute('role', 'navigation');
    el.appendChild(this._secNavEl);
  }


  private _markAsActive(li: HTMLElement, isActive: boolean) {
    if (isActive) {
      li.classList.add(this.options.activeCssClass);
    } else {
      li.classList.remove(this.options.activeCssClass);
    }
  }

  private _isActive(currentUrl: string, li: HTMLElement): boolean {
    return this.isMenuItemActive(currentUrl, li.querySelector<HTMLAnchorElement>('a')!.href);
  }

  protected async initializeCore(): Promise<void> {
    /* NOOP */
  }

  protected override removeNavElement(): void {
    if (this._secNavEl) {
      this._secNavEl.parentElement!.removeChild(this._secNavEl);
    }
    super.removeNavElement();
  }

  protected override toggle(isVisible = false): void {
    if (this._secNavEl) {
      if (isVisible) {
        this._secNavEl.classList.remove(this.options.hiddenCssClass);
      } else {
        this._secNavEl.classList.add(this.options.hiddenCssClass);
      }
    }

    super.toggle(isVisible);
  }

  protected async disposeCore(): Promise<void> {
    this._removeEventListeners();
  }

  protected async render(): Promise<void> {
    const remoteCalls = await Promise.all([
      this._getMainMenuItems(),
      this._getUserDropDownMenuItems()
    ]);

    this._navbarData.mainMenuItems = remoteCalls[0];
    this._navbarData.userDropDownMenuItems = remoteCalls[1];

    this._cleanNavContent();

    const logoDiv: HTMLElement = this.window.document.createElement('div');
    logoDiv.className = 'navbar-logo';
    this.navEl.appendChild(logoDiv);

    this._createMainNavMenu();
    this._createMainNavUser();
  }

  protected refreshActiveLinks(): void {
    const currentUrl: string = this.window.location.href;
    const functionalityMap: { [id: string]: IMenuItem } = {};

    this._navbarData.mainMenuItems.forEach(f => {
      functionalityMap[f.functionalityId] = f;
    });

    this._navbarMenuEl?.querySelectorAll<HTMLElement>('li')?.forEach(li => {

      const functionality = functionalityMap[li.getAttribute('data-functionality-id')!];

      if (functionality && functionality.items && this.isMenuItemRelatedToApp(functionality)) {
        // this functionality has child functionalities so we need to check if the children are related to the current application and if any of them are active.
        let isActive = false;
        const children = this._secMenuEl?.querySelectorAll<HTMLElement>(`li[data-parent-functionality-id=${functionality.functionalityId}]`);

        children?.forEach(childLi => {
          childLi.classList.add(this.options.hiddenCssClass); // hide all of the children

          if (this._isActive(currentUrl, childLi)) {
            isActive = true;
            this._markAsActive(childLi, true);
          } else {
            this._markAsActive(childLi, false);
          }
        });

        this._markAsActive(li, isActive);

        if (isActive) {
          // remove hidden class from children below an active parent
          children!.forEach(childLi => {
            childLi.classList.remove(this.options.hiddenCssClass);
          });
        }

      } else {
        this._markAsActive(li, this._isActive(currentUrl, li));
      }
    });
  }
}

