/* tslint:disable no-reference */
/// <reference path="../../../../node_modules/@types/bootstrap/index.d.ts" />
/// <reference path="../../../../node_modules/@types/jquery/index.d.ts" />
import { CONST } from '../../../index';
import { II18nManager } from '../../../core-components/index';
import { INotificationsComponent } from '../../contracts/index';
import { IShowNotification } from '../../../messages/index';
import { setStyle } from '../../../utilities';

const groupAttribute = 'data-group';
const hasItemsClass = 'has-items';

/**
 * Component to display notifications to the end user as toast messages.
 */
export class ToastNotificationsComponent implements INotificationsComponent {
  private readonly _window: Window;
  private readonly _container: HTMLElement;
  private readonly _i18nManager: II18nManager;
  private readonly _$: JQueryStatic;

  /**
   * Constructor.
   *
   * @param {Window} window The current window reference.
   * @param {I18nManager} i18nManager Internationalization manager.
   */
  constructor(window: Window, i18nManager: II18nManager) {
    this._window = window;
    this._i18nManager = i18nManager;
    this._container = this._window.document.createElement('div');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this._$ = (this._window as any).$ as JQueryStatic;
  }

  /**
   * @inheritdoc
   */
  public async initialize(): Promise<void> {
    const wrapper = this._window.document.createElement('div');
    wrapper.appendChild(this._container);
    wrapper.classList.add('notifications');
    wrapper.setAttribute('aria-live', 'polite');
    wrapper.setAttribute('aria-atomic', 'true');
    this._container.classList.add('notifications-list');
    this._window.document.body.appendChild(wrapper);
    setStyle(this._window.document, `
    .notifications {
      position: absolute;
      top: 0;
      right: 0;
      width: 350px;
      z-index: 10;
    }

    .notifications .notifications-list {
      position: relative;
      top: 0;
      right: 0;
    }

    .notifications .notifications-list.has-items {
      padding: 1rem;
    }
    `, 'toast-notification-component-styles');
  }

  /**
   * @inheritdoc
   */
  public notify(notification: IShowNotification): void {
    if (notification.group && notification.single) {
      this.clearNotifications(notification.group);
    }

    const toast = this._window.document.createElement('div');
    toast.className = 'toast';
    toast.setAttribute('role', 'alert');
    toast.setAttribute('aria-live', 'assertive');
    toast.setAttribute('aria-atomic', 'true');
    if (notification.group) {
      toast.setAttribute(groupAttribute, notification.group);
    }

    const toastHeader = this._window.document.createElement('div');
    toastHeader.className = 'toast-header';
    toast.appendChild(toastHeader);

    const toastHeaderTitle = this._window.document.createElement('strong');
    toastHeaderTitle.className = 'mr-auto';
    toastHeaderTitle.textContent = notification.title;
    toastHeader.appendChild(toastHeaderTitle);

    if (typeof notification.dismissable !== 'boolean' || notification.dismissable) {
      const toastCloseBtn = this._window.document.createElement('button');
      toastCloseBtn.type = 'button';
      toastCloseBtn.className = 'ml-2 mb-1 close';
      toastCloseBtn.setAttribute('data-dismiss', 'toast');
      toastCloseBtn.setAttribute('aria-label', this._i18nManager.translate(CONST.i18N.CMN_Close));

      const toastCloseBtnSpan = this._window.document.createElement('span');
      toastCloseBtnSpan.setAttribute('aria-hidden', 'true');
      toastCloseBtnSpan.appendChild(this._window.document.createTextNode('\u00D7'));

      toastCloseBtn.appendChild(toastCloseBtnSpan);
      toastHeader.appendChild(toastCloseBtn);
    }

    const toastBody = this._window.document.createElement('div');
    toastBody.className = 'toast-body';
    toast.appendChild(toastBody);

    const toastBodyText = this._window.document.createElement('p');
    toastBodyText.textContent = notification.body;
    toastBody.appendChild(toastBodyText);

    if (notification.detailsUrl) {
      const readMore = this._window.document.createElement('a');
      readMore.rel = 'nofollow';
      readMore.target = '_blank';
      readMore.title = this._i18nManager.translate(CONST.i18N.CMN_ReadMore);
      readMore.text = readMore.title;
      readMore.href = notification.detailsUrl;
      toastBody.appendChild(readMore);
    }

    this._$(toast)
      .toast({ autohide: false })
      .on('hidden.bs.toast', (e: JQuery.TriggeredEvent) => this.removeToast(e))
      .toast('show')
      .appendTo(this._container);

    this._container.classList.add(hasItemsClass);
  }

  /**
   * @inheritdoc
   */
  public clearNotifications(group?: string): void {
    let $toasts = this._$(this._container).find('div.toast');
    if (group) {
      $toasts = $toasts.filter((_idx, el) => this._$(el).attr(groupAttribute) === group);
    }

    this.disposeToasts($toasts);
  }

  /**
   * @inheritdoc
   */
  public dispose(): Promise<void> {
    try {
      this.clearNotifications();
      const containerWrapper = this._container.parentElement;
      this._window.document.body.removeChild(containerWrapper as HTMLElement);

    } catch {
      // Do nothing
    }
    return Promise.resolve();
  }

  private removeToast(e: JQuery.TriggeredEvent): void {
    this.disposeToasts(this._$(e.target));
  }

  private disposeToasts($toasts: JQuery<Element>) {
    $toasts
      .off('hidden.bs.toast')
      .toast('dispose')
      .remove();

    if (this._container.children.length === 0) {
      this._container.classList.remove(hasItemsClass);
    }
  }
}
