import { I18nManager } from '../contracts/i18nManager';
import { Logger, LogLevel, LogMessage } from '@validide/logger';
import { sendAsync } from '../../utilities/index';
import { IMessageBus, BusMessage } from '../contracts/index';

export class RemoteI18nManager extends I18nManager {
  private readonly _logger: Logger;
  private readonly _url: string;
  private readonly _version: string;
  private readonly _bus: IMessageBus | null;
  private readonly _eventName: string;
  private _defaultCulture: string;

  /**
   * Constructor
   *
   * @param {String} url The remove URL form where to load the translation resources.
   * This will replace the `PLACEHOLDER_LANG` text in the URL with the provided "lang" and the `PLACEHOLDER_VERSION` text with the application version in the URL.
   * @param {String} version The version of the resources.
   * @param {Logger} logger  Logger implementation.
   * @param {RegExp} i18nCaptureExpression Regular expression used to match the parts of a text that needs to be translated.
   * @param {String} defaultCulture The default culture to use in case a culture or "false" culture is provided.
   * @param {String} eventName The name of the event that will be published when the culture updates.
   * @param {IMessageBus} bus The message bus to publish the message to.
   */
  constructor(
    url: string,
    version: string,
    logger: Logger,
    i18nCaptureExpression?: RegExp,
    defaultCulture?: string,
    eventName?: string,
    bus?: IMessageBus
  ) {
    super(i18nCaptureExpression || /\{i18n\.(\w*)\}/gm);
    this._url = url;
    this._version = version;
    this._logger = logger;
    this._eventName = eventName || 'CMN.I18N.RESOURCES_UPDATED';
    this._bus = bus || null;
    this._defaultCulture = defaultCulture || 'en';
  }

  /**
   * @inheritdoc
   */
  public async loadResources(): Promise<void> {
    try {
      const requestCulture = this.culture || (this.setCultureInternal(this._defaultCulture) as string);
      const requests: Promise<null | { [id: string]: string }>[] = [
        this._requestRemoteTranslations(requestCulture)
      ];

      if (requestCulture.indexOf('-') !== -1) {
        requests.push(this._requestRemoteTranslations(requestCulture.split('-')[0]));
      }

      const responses = await Promise.all(requests);
      const translations = responses[0] ? responses[0] : responses[1];
      if (typeof translations === 'object' && translations != null) {
        const keys = Object.keys(translations);
        for (const key of keys) {
          this.addTranslation(key, translations[key]);
        }
        if (this._bus) {
          this._bus.publish(new BusMessage<string>(this._eventName, requestCulture)
          );
        }
      } else {
        const msg = new LogMessage();
        msg.level = LogLevel.Warning;
        msg.message = 'Load Translations failed due to missing data.';
        msg.extraParams = {
          value: translations
        };
        this._logger.logMessage(msg);
      }

    } catch (error: any) {
      const msg = new LogMessage();
      msg.level = LogLevel.Error;
      msg.message = 'Load Translations failed due to network error.';
      msg.extraParams = {
        error: error
      };
      this._logger.logMessage(msg);
    }
  }

  private async _requestRemoteTranslations(culture: string): Promise<null | { [id: string]: string }> {
    const url = this._url
      .replace('PLACEHOLDER_LANG', culture.toLowerCase())
      .replace('PLACEHOLDER_VERSION', this._version || '');

    const result = await sendAsync({ url: url });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result.request.status === 200
      ? JSON.parse(result.request.responseText)
      : null;
  }
}
