import { II18nManager } from '../../core-components';
import { CookieUtilities } from '../../utilities';
import { i18N } from '../../constants';
import { ICookieInformationComponent } from '../contracts/iCookieInformationComponent';

/**
 * Component to display the cookie information.
 */
export class CookieInformationComponent implements ICookieInformationComponent {
  private static readonly KEY_OLD = 'ES.CA';
  private static readonly KEY_NEW = 'CS.CA';
  protected static readonly BANNER_CSS_CLASS = 'cookie-banner';
  protected static readonly BANNER_OK_BUTTON_CSS_CLASS = 'cookie-banner-ok';
  protected static readonly SLIDING_SESSION_INCREMENT = 30;

  private readonly _displayDelay: number;
  protected readonly window: Window;
  protected readonly i18nManager: II18nManager;
  protected readonly readMoreUri: string;

  protected bannerEl: HTMLElement | null = null;
  protected okEl: HTMLAnchorElement | null = null;

  /**
   * Constructor.
   *
   * @param {window} window Reference to the current window.
   * @param {II18nManager} i18nManager Manager instance used for translations.
   * @param {string} readMoreUri URL placed in the read more button.
   * @param {number} displayDelay The delay after which the cookie check and eventual rendering is made
   */
  constructor(window: Window, i18nManager: II18nManager, readMoreUri: string, displayDelay?: number) {
    this.window = window;
    this.i18nManager = i18nManager;
    this.readMoreUri = readMoreUri;
    this._displayDelay = displayDelay ?? 5_000;
  }

  private isAckValid(): boolean {
    const cookieUtils = new CookieUtilities(this.window);
    if (cookieUtils.get(CookieInformationComponent.KEY_NEW) === '1')
      return true;

    if (cookieUtils.get(CookieInformationComponent.KEY_OLD) === '1') {
      cookieUtils.set(CookieInformationComponent.KEY_NEW, cookieUtils.get(CookieInformationComponent.KEY_OLD));
      return true;
    }

    return false;
  }

  private extendAck(): void {
    new CookieUtilities(this.window)
      .set(CookieInformationComponent.KEY_NEW, '1', CookieInformationComponent.SLIDING_SESSION_INCREMENT);
  }

  protected hide(): void {
    if (this.bannerEl && this.okEl) {
      this.bannerEl.style.display = 'none';

      this.okEl.removeEventListener('click', this.acknowledge);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.bannerEl.parentElement!.removeChild(this.bannerEl);
    }
  }

  protected show(): void {
    const banner = this.buildCookieBanner();
    banner.style.display = 'block';
    this.window.document.body.appendChild(banner);
  }

  protected buildCookieBanner(): HTMLElement {
    this.bannerEl = this.window.document.createElement('p');
    this.bannerEl.classList.add(CookieInformationComponent.BANNER_CSS_CLASS, 'bg-primary', 'pos-f-b', 'text-center');
    this.bannerEl.style.display = 'none';
    this.bannerEl.textContent = this.i18nManager.translate(i18N.CMN_CookiesInfoText) + '\xa0' + '\xa0'; // Add 2 "&nbsp;"

    const readMoreText = this.i18nManager.translate(i18N.CMN_ReadMore);
    const readMoreEl = this.window.document.createElement('a');
    readMoreEl.classList.add('text-white');
    readMoreEl.href = this.readMoreUri;
    readMoreEl.rel = 'nofollow noopener noreferrer';
    readMoreEl.target = '_blank';
    readMoreEl.title = readMoreText;
    readMoreEl.textContent = readMoreText;

    const okText = this.i18nManager.translate(i18N.CMN_Accept);
    this.okEl = this.window.document.createElement('a');
    this.okEl.classList.add(CookieInformationComponent.BANNER_OK_BUTTON_CSS_CLASS);
    this.okEl.href = 'javascript void 0;';
    this.okEl.rel = 'nofollow noopener noreferrer';
    this.okEl.target = '_blank';
    this.okEl.title = okText;
    this.okEl.textContent = okText;

    this.okEl.addEventListener('click', this.acknowledge.bind(this));

    this.bannerEl.appendChild(readMoreEl);
    this.bannerEl.appendChild(this.okEl);

    return this.bannerEl;
  }

  protected acknowledge(e: Event): void {
    e.preventDefault();
    new CookieUtilities(this.window).set(CookieInformationComponent.KEY_NEW, 1);
    this.hide();
    this.extendAck();
  }

  private checkAndShow(): void {
    if (this.isAckValid()) {
      this.extendAck();
      return;
    }
    this.show();
  }

  /**
   * Check if the user accepted and possibly show the banner.
   */
  public check(): void {
    this.window.setTimeout(
      () => this.checkAndShow(),
      this._displayDelay
    );
  }

  /**
   * Dispose the current instance.
   */
  public dispose(): Promise<void> {
    this.hide();
    return Promise.resolve();
  }
}
