export class Result<T> {
  private _error: Error | null = null;
  private _value: T | null = null;

  public isSuccess = false;

  public get error(): Error|null {
    if (this.isSuccess) {
      throw new Error('Result is successful.');
    }

    if (this._error === null) {
      throw new Error('Result is failure but error was not set.');
    }

    return this._error;
  }

  public set error(value: Error|null) {
    this._error = value;
    this.isSuccess = false;
  }

  public get value(): T|null {
    if (!this.isSuccess) {
      throw new Error('Result is failure.');
    }

    if (this._value === null) {
      throw new Error('Result is success but value was not set.');
    }

    return this._value;
  }

  public set value(value: T|null) {
    this._value = value;
    this.isSuccess = true;
  }
}
