/**
 * Get the names of the members of an enum.
 *
 * @param {Enum} theEnum The enum to get the values for.
 *
 */
export const getEnumMembers = (theEnum: Record<string, unknown>): string[] => {
  const names: string[] = [];
  for (const n in theEnum) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof (theEnum as any)[n] === 'number') {
      names.push(n);
    }
  }

  if (names.length === 0) {
    // We might be in the situation where we have an enum with string values.
    // Just push all the keys.
    // eslint-disable-next-line guard-for-in
    for (const n in theEnum) {
      names.push(n);
    }
  }
  return names;
};
