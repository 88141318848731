/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LogLevel } from '@validide/logger';
import { ConfigurationKeys, IHostApplication } from '../../../host/index';
import { CONST } from '../../../index';
import { Types as MT } from '../../../messages/index';
import { sendAsync } from '../../../utilities/index';
import { IEventListenerData, IMenuItem } from './iMenuItems';
import { Navbar } from './navBar';

interface ISideNavbarData {
  sideMenuItems: IMenuItem[];
}

/**
 * Side navigation bar.
 */
export class SideNavbar extends Navbar {
  protected navbarSideMenuEl: HTMLElement | null = null;

  private _linksWithEventListeners: IEventListenerData[] = [];
  private _clickLinksHandlerRef: (event: Event, data?: any) => void;

  /**
   * Constructor.
   *
   * @param {Window} window A window reference.
   * @param {IHostApplication} host Host application reference.
   * @param {HTMLElement} el The navigation bar parent element.
   */
  constructor(window: Window, host: IHostApplication, el: HTMLElement) {
    super(window, host, el, {
      activeCssClass: 'active',
      hiddenCssClass: 'd-none',
      rootElementCssClasses: ['card'],
      visibilityToggleMessage: MT.C.CMN_SIDE_NAVBAR_VISIBILITY_TOGGLE
    });

    this._clickLinksHandlerRef = e => { this.clickLinksHandler(e); };
  }
  protected async initializeCore(): Promise<void> {
    /* NOOP */
  }
  protected async disposeCore(): Promise<void> {
    this._removeEventListeners();
  }
  protected async render(): Promise<void> {
    const options: ISideNavbarData = {
      sideMenuItems: await this._getSideMenuItems()
    };

    this._cleanNavContent();

    const sideNavHeader: HTMLElement = this._createSideNavHeader();
    const sideNavBody: HTMLElement = this._createSideNavBody(options);

    this.navEl.appendChild(sideNavHeader);
    this.navEl.appendChild(sideNavBody);
  }

  protected refreshActiveLinks(): void {
    const currentUrl: string = this.window.location.href;
    const navbarMenuList: HTMLElement | null = this.navbarSideMenuEl;
    const navbarMenuItems = navbarMenuList ? navbarMenuList.querySelectorAll('li') : [];

    navbarMenuItems.forEach((li: HTMLElement) => {
      const link = li.querySelector<HTMLAnchorElement>('a');
      if (link) {
        if (this.isMenuItemActive(currentUrl, link.href)) {
          link.classList.add(this.options.activeCssClass);
        } else {
          link.classList.remove(this.options.activeCssClass);
        }
      }
    });
  }

  /**
   * Fetch the side menu entries.
   *
   * @returns { Promise<IMenuItem[]> } the side menu items
   */
  protected async _getSideMenuItems(): Promise<IMenuItem[]> {
    if (!this.user || !this.user.accessToken || this.user.expired) {
      return [];
    }

    const hostname = encodeURIComponent(this.window.location.hostname);
    const remoteResponse = await sendAsync({
      url: this.host.buildUrl(`${this.host.configuration[ConfigurationKeys.sideNavMenuUrl]!}?domainUri=${hostname}`),
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.user.accessToken}`
      }
    });

    if (remoteResponse.request.status !== 200) {
      this.host.loggerFactory.getLogger('SideNavbar').log(LogLevel.Error, 'Failed to load side navigation menu.', undefined, { 'HTTP_STATUS': remoteResponse.request.status});
      return [];
    }
    return (JSON.parse(remoteResponse.request.responseText) as IMenuItem[]);
  }

  private _cleanNavContent(): void {
    // Clean previous elements and handlers.
    this._removeEventListeners();
    while (this.navEl.firstChild) {
      this.navEl.removeChild(this.navEl.firstChild);
    }
  }

  /**
   * Creates the side nav header element.
   *
   * @returns {HTMLElement}
   */
  private _createSideNavHeader(): HTMLElement {
    const header: HTMLElement = this.window.document.createElement('div');
    header.className = 'card-header';

    const title: HTMLElement = this.window.document.createElement('h5');
    title.innerText = this.host.i18nManager.translate(CONST.i18N.CMN_Settings);

    header.appendChild(title);
    return header;
  }

  /**
   * Creates the side nav body element.
   *
   * @param {ISideNavbarData} options
   * @returns {HTMLElement}
   */
  private _createSideNavBody(options: ISideNavbarData): HTMLElement {
    const body: HTMLElement = this.window.document.createElement('div');
    body.className = 'card-body';

    const menuList: HTMLElement = this.window.document.createElement('ul');
    menuList.className = 'menu-left';
    menuList.setAttribute('role', 'navigation');

    options.sideMenuItems.forEach(groupItem => {
      const menuItem: HTMLElement = this.window.document.createElement('li');
      menuItem.className = 'menu-group-title';
      menuItem.innerText = groupItem.label;
      menuList.appendChild(menuItem);

      if (groupItem.items) {
        groupItem.items.forEach(innerGroupItem => {
          const menuItemEl: HTMLElement = this.window.document.createElement('li');
          const sideMenuLinkItem: HTMLAnchorElement = this.window.document.createElement('a');
          sideMenuLinkItem.href = this.host.buildUrl(innerGroupItem.url);
          sideMenuLinkItem.innerText = innerGroupItem.label;
          sideMenuLinkItem.rel = 'nofollow noopener noreferrer';

          if (this.isCurrentApp(sideMenuLinkItem.href)) {
            this._addEventListener(sideMenuLinkItem, 'click', this._clickLinksHandlerRef);
          }

          menuItemEl.appendChild(sideMenuLinkItem);
          menuList.appendChild(menuItemEl);
        });
      }
    });

    this.navbarSideMenuEl = menuList;
    body.appendChild(menuList);
    return body;
  }

  /**
   * Adds event handler.
   */
  private _addEventListener(element: HTMLAnchorElement, eventName: string, handler: ((event: Event, data?: any) => void)) {
    this._linksWithEventListeners.push({ element: element, eventName: eventName, handler: handler });
    element.addEventListener(eventName, handler);
  }

  /**
   * Removes event handlers.
   */
  private _removeEventListeners() {
    this._linksWithEventListeners.forEach((evListenerData: IEventListenerData) => {
      evListenerData.element.removeEventListener(evListenerData.eventName, evListenerData.handler);
    });
  }
}
